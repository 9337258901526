(function () {
    'use strict';

    angular
        .module('ap')
        .component('affiliateInfo', AffiliateInfo());

    /**
     * @ngdoc component
     * @name ap.campaigns
     *
     * @description Component function for passing the affiliates info
     * @returns {{controller: ap.AffiliateInfoController, controllerAs: string, templateUrl: string}}
     * @constructor
     */
    function AffiliateInfo() {
        return {
            controller: AffiliateInfoController,
            controllerAs: 'ctrl',
            templateUrl: '/views/campaigns.account.table.html'
        };
    }

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    AffiliateInfoController.$inject = [
        'accountFactory',
        'ErrorFactory'
    ];

    /**
     * @ngdoc AffiliateInfoController
     * @name ap.campaigns
     *
     * @description Component function for passing the affiliates info
     *
     * @param accountFactory
     * @param ErrorFactory
     * @constructor
     */
    function AffiliateInfoController(accountFactory,
                                     ErrorFactory
    ) {
        var ctrl = this;
        ctrl.loading = true;
        init();

        /**
         * @ngdoc init
         * @name ap.campaigns
         *
         * @description gets the necessary information to view the page
         */
        function init() {
            accountFactory.getInfo()
                .then(function (response) {
                    ctrl.affiliate = response.data.data;
                })
                .catch(ErrorFactory.outputError)
                .finally(function (response) {
                    ctrl.loading = false;
                });
        }
    }
})();
