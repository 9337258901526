(function () {
    'use strict';

    angular
        .module('ap')
        .factory('accountFactory', accountFactory);

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    accountFactory.$inject = ['http'];

    /**
     * @ngdoc factory
     * @name ap.account
     * @param $http
     * @returns {{getInfo: getInfo, saveUserInfo: saveUserInfo}}
     */
    function accountFactory(http) {
        var baseUri = '/api/v1/';

        /**
         * @ngdoc factory
         * @name ap.account
         * @description Factory object that gets returned when the service is injected
         * @type {{getInfo: getInfo, saveUserInfo: saveUserInfo}}
         */
        return {
            getInfo: getInfo,
            saveUserInfo: saveUserInfo,
            savePaymentInfo: savePaymentInfo
        };

        /**
         * @ngdoc Get Info
         * @name ap.account
         * @description gets the user information
         */
        function getInfo() {
            return http.get(baseUri + 'account');
        }

        /**
         * @ngdoc saveUserInfo
         * @name ap.account
         * @description saves the user information
         * @param user
         */
        function saveUserInfo(user) {
            return http.post(baseUri + 'account', {user: user});
        }

        /**
         * @ngdoc savePaymentInfo
         * @name ap.account
         * @description saves the affilifate payment info
         * @param payment
         */
        function savePaymentInfo(payment) {
            return http.post(baseUri + 'account/payment', {payment: payment});
        }
    }
})();
