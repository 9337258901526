(function() {
    angular
        .module('ap')
        .factory('CsvFactory', CsvFactory);

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    CsvFactory.$inject = ['statsFactory'];

    /**
     * @ngdoc factory
     * @name ap
     * @description export CSV files
     */
    function CsvFactory(statsFactory) {
        const currencyLocaleMap = statsFactory.currencyLocaleMap;

        var statsHeaders = [
            'Campaign ID',
            'Campaign Name',
            'Clicks',
            'Leads',
            'Revenue',
            'Earnings per Click'
        ];

        var statsFields = [
            'id', 
            'name', 
            'clicks', 
            'credited_leads',
            'credited_cost', 
            'credited_cpc'
        ];

        var pivotHeaders = [
            'Date',
            'Clicks',
            'Leads',
            'Revenue',
            'Earnings per Click'
        ];

        var pivotFields = [
            'date',
            'clicks',
            'credited_leads',
            'credited_cost',
            'credited_cpc'
        ];

        var tokenHeaders = [
            'Sub Affiliate',
            'Clicks',
            'Leads',
            'Revenue',
            'Earnings per Click'
        ];

        var tokenFields = [
            'click_c10',
            'clicks',
            'credited_leads',
            'credited_cost',
            'credited_cpc'
        ];
        
        var currencyFields = [
            'credited_cost',
            'credited_cpc'
        ];

        var factory = {
            statsHeaders: statsHeaders,
            statsFields: statsFields,
            pivotHeaders: pivotHeaders,
            pivotFields: pivotFields,
            tokenHeaders: tokenHeaders,
            tokenFields: tokenFields,
            createCsv: createCsv
        };

        return factory;

        // Creates data for export CSV
        function createCsv(data, fields, currency) {
            var csvData = [];

            // Loop through every row in table
            for (var i = 0; i < data.length; i++) {
                var row = [];

                // Loop through each field, adding data to the row
                for (var j = 0; j < fields.length; j++) {

                    // If key doesn't exist in data array, set field to 0
                    if (data[i][fields[j]]) {
                        // Convert value if it is a currency field
                        if (currencyFields.includes(fields[j])) {
                            row.push(currencyFormatter(currency, data[i][fields[j]]));
                        } else {
                            row.push(data[i][fields[j]]);
                        }

                    } else {
                        if (fields[j] === 'credited_cpc') {
                            var epc = 0;
                            if (data[i].clicks === 0) {
                                epc = 0;
                            } else {
                                epc = parseFloat(data[i].credited_cost / data[i].clicks);
                            }
                            row.push(epc);
                        } else {
                            row.push(0);
                        }
                    }
                }
                // Push a whole row to the final CSV
                csvData.push(row);
            }

            return csvData;
        }

        /**
         * @ngdoc component
         *
         * @description returns string of formatted currency value without symbol
         */
        function currencyFormatter(currency, value) {
            value = parseFloat(value) * parseFloat(currency.conversion_rate);

            return value.toLocaleString(currencyLocaleMap[currency.name], {
                minimumFractionDigits: 6,
                maximumFractionDigits: 6
            });

        }
    }
})();
