(function() {
    'use strict';

    angular
        .module('ap')
        .component('accountInfo', accountInfo());

    /**
     * @ngdoc component
     * @name ap.account
     *
     * @description Component function for rendering the account information page
     */
    function accountInfo() {
        return {
            controller: AccountController,
            controllerAs: 'vm',
            templateUrl: '/views/account.form.html'
        };
    }

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    AccountController.$inject = ['$q', '$uibModal', 'accountFactory', 'CONSTANTS', 'Notification', 'ValidationFactory', 'statsFactory'];

    /**
     * @ngdoc Account Controller
     * @name ap.account
     * @param $q
     * @param accountFactory
     * @constructor
     */
    function AccountController($q, $uibModal, accountFactory, CONSTANTS, Notification, ValidationFactory, statsFactory) {
        var vm = this;

        vm.loading = true;
        vm.tooltip = 'This page has your information as a user.';
        vm.countries = CONSTANTS.countries.ARRAY;
        vm.states = CONSTANTS.states;
        vm.timezones = CONSTANTS.timezones;
        vm.maxHeight = '750px';
        vm.suppression_id = null;

        vm.toggleSameBillingContact = toggleSameBillingContact;
        vm.saveContact = saveContact;
        vm.downloadSuppressionList = downloadSuppressionList;

        vm.levels = getLevels();

        initDependencies();

        /**
         * @ngdoc initDependencies
         * @name ap.account
         *
         * @description gets the necessary information to view the page
         */
        function initDependencies() {
            var promises = [
                accountFactory.getInfo(),
                statsFactory.checkForNotifications()
            ];

            $q.all(promises)
                .then(function (response) {
                    vm.user = response[0].data.data;
                    vm.suppression_id = response[1].data.data.suppression_id
                })
                .catch(function (response) {

                })
                .finally(function (response) {
                    vm.loading = false;
                })
        }

        /**
         * @ngdoc toggleSameBillingContact
         * @name ap.account
         *
         * @description toggle the checkbox for same contact and billing information
         */
        function toggleSameBillingContact() {
            if (vm.user) {
                if (vm.contactBillingSame) {
                    vm.user.affiliate.billing_first_name = vm.user.affiliate.contact_first_name;
                    vm.user.affiliate.billing_last_name = vm.user.affiliate.contact_last_name;
                    vm.user.affiliate.billing_address_1 = vm.user.affiliate.contact_address_1;
                    vm.user.affiliate.billing_address_2 = vm.user.affiliate.contact_address_2;
                    vm.user.affiliate.billing_city = vm.user.affiliate.contact_city;
                    vm.user.affiliate.billing_state = vm.user.affiliate.contact_state;
                    vm.user.affiliate.billing_country = vm.user.affiliate.contact_country;
                    vm.user.affiliate.billing_zip = vm.user.affiliate.contact_zip;
                    vm.user.affiliate.billing_email = vm.user.affiliate.contact_email;
                    vm.user.affiliate.billing_phone = vm.user.affiliate.contact_phone;
                } else {
                    vm.user.affiliate.billing_first_name = null;
                    vm.user.affiliate.billing_last_name = null;
                    vm.user.affiliate.billing_address_1 = null;
                    vm.user.affiliate.billing_address_2 = null;
                    vm.user.affiliate.billing_city = null;
                    vm.user.affiliate.billing_state = null;
                    vm.user.affiliate.billing_country = null;
                    vm.user.affiliate.billing_zip = null;
                    vm.user.affiliate.billing_email = null;
                    vm.user.affiliate.billing_phone = null;
                }
            }
        }

        /**
         * @ngdoc getLevels
         * @name ap.account
         *
         * @description gets a list of levels of experience
         */
        function getLevels() {
            return [
                'I am a complete beginner.',
                'I have a little bit of experience, but I\'ve never received payment from an affiliate network.',
                'I have some experience and have received at least one payment from an affiliate network.',
                'I am extremely experienced, and have received many payments from affiliate networks.'
            ]
        }

        function downloadSuppressionList() {
            if (!vm.suppression_id) {
                return;
            }
            statsFactory.createSuppressionLog(vm.suppression_id);
        }

        /**
         * @ngdoc saveContact
         * @name ap.account
         *
         * @description saves the users contact information
         */
        function saveContact() {
            // Regex validator on the contact email
            if (vm.user.affiliate.contact_email && !ValidationFactory.email.test(vm.user.affiliate.contact_email)) {
                Notification.warning({
                    message: 'The contact email you entered is not a valid email. Please enter a valid one.',
                    title: 'Warning'
                });

                return false;
            }

            // Regex validator on the billing email
            if (vm.user.affiliate.billing_email && !ValidationFactory.email.test(vm.user.affiliate.billing_email)) {
                Notification.warning({
                    message: 'The billing email you entered is not a valid email. Please enter a valid one.',
                    title: 'Warning'
                });

                return false;
            }

            // Regex validator on the Paypal email
            if (vm.user.affiliate.banking_paypal_email && !ValidationFactory.email.test(vm.user.affiliate.banking_paypal_email)) {
                Notification.warning({
                    message: 'The Paypal email you entered is not a valid email. Please enter a valid one.',
                    title: 'Warning'
                });

                return false;
            }

            if (vm.user.default_postback) {
                // Regex validator on the default postback
                if (!ValidationFactory.pbUrl.test(vm.user.default_postback) || !ValidationFactory.url.test(vm.user.default_postback)) {
                    Notification.warning({
                        message: 'The postback URL you entered is not valid. Please enter a valid one.',
                        title: 'Warning'
                    });

                    return false;
                }
            }

            vm.loading = true;

            var promises = [
                accountFactory.saveUserInfo(vm.user)
            ];

            $q.all(promises)
                .then(function(response) {

                })
                .catch(function(response) {

                })
                .finally(function(response) {
                    vm.loading = false;
                })
        }
    }
})();
