(function () {
    'use strict';

    angular
        .module('ap')
        .component('pivotTable', pivotTable());

    /**
     * @ngdoc pivotTable
     * @name ap.pivot
     * @description compnent for the stats table
     * @returns {{controller: PivotController, controllerAs: string, templateUrl: string}}
     */
    function pivotTable() {
        return {
            controller: PivotController,
            controllerAs: 'vm',
            templateUrl: '/views/pivot.table.html'
        };
    }

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    PivotController.$inject = [
        '$q', '_', 'statsFactory', 'campaignsFactory', 'DateFactory', 'ErrorFactory', 'sideMenuFactory', 'Notification', 'CsvFactory'
    ];

    /**
     * @ngdoc PivotController
     * @name ap.pivot
     * @description controller for the pivot table
     * @param $q
     * @param _
     * @param statsFactory
     * @param campaignsFactory
     * @param DateFactory
     * @param ErrorFactory
     * @param sideMenuFactory
     * @param Notification
     * @param CsvFactory
     * @constructor
     */
    function PivotController($q, _, statsFactory, campaignsFactory, DateFactory, ErrorFactory, sideMenuFactory, Notification, CsvFactory) {
        var vm = this;
        vm.tooltip = 'This page allows you to pivot each campaign to see their respective daily stats. Select a ' +
            'campaign and then a date to view their individual stats';

        var rowHeight = 35;
        const decimalPlaces = 6;
        vm.datePicker = {};
        vm.pageSize = 12;
        vm.loading = true;

        vm.datePicker.date = DateFactory.currentDate();
        vm.dateOptions = DateFactory.dateOptions();

        vm.exportCsv = exportCsv;
        vm.pivotCsvHeaders = CsvFactory.pivotHeaders;

        vm.onFilterChange = onFilterChange;
        vm.getStats = getDailyStats;

        // Set default currency to USD
        vm.currency = {
            name: 'USD',
            conversion_rate: '1.0000000'
        };

        init();

        /**
         * @ngdoc component
         * @name ap.pivot
         *
         * @description gets page dependencies
         */
        function initDependencies() {
            // Remove Last 30 days from datepicker
            delete vm.dateOptions.ranges['Last 30 Days'];

            var promises = {
                'campaigns': campaignsFactory.getAffiliateCampaigns(),
                'env': sideMenuFactory.getEnvVariables()
            };

            $q.all(promises)
                .then(function (response) {
                    vm.campaigns = response['campaigns'].data.data;

                    if (response['env'].data && response['env'].data.currency) {
                        // Overwrite default USD if currency is set for affiliate
                        vm.currency = response['env'].data.currency;
                    }
                    filterAffiliateCampaigns();
                })
                .catch(ErrorFactory.outputError)
                .finally(function (response) {
                    vm.loading = false;
                })
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         *
         * @description begins the page
         */
        function init() {
            vm.getHeight = ((vm.pageSize + 1) * rowHeight) + 53 + 'px';
            vm.gridOptions = getGridOptions();
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         *
         * @description sets the table data
         */
        function setGridData(dataArray) {
            if (vm.gridOptions.api) {
                vm.gridOptions.api.setRowData(dataArray);
            }
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         *
         * @description search filter for the table
         */
        function onFilterChange() {
            vm.gridOptions.api.setQuickFilter(vm.filter);
            if (vm.gridOptions.api.rowModel.rowsToDisplay.length === 0) {
                setGridData([]);
            }
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         *
         * @description sets the grid options for ag-grid
         */
        function getGridOptions() {
            var columnDefs = getColumnDefs();

            return {
                headerHeight: rowHeight,
                colWidth: 170,
                columnDefs: columnDefs,
                rowHeight: rowHeight,
                rowSelection: 'single',
                rowData: null,
                enableSorting: true,
                enableFilter: true,
                enableColResize: true,
                suppressMenuHide: true,
                suppressCellSelection: true,
                angularCompileRows: true,
                onGridReady: function (event) {
                    initDependencies();
                }
            };
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         *
         * @description gets array of the columns on the campaigns for
         * ag-grid table
         */
        function getColumnDefs() {
            return [
                {
                    headerName: 'Date',
                    field: 'date',
                    suppressMenu: true
                }, {
                    headerName: 'Clicks',
                    field: 'clicks',
                    suppressMenu: true
                }, {
                    headerName: 'Leads',
                    field: 'credited_leads',
                    suppressMenu: true
                }, {
                    headerName: 'Revenue',
                    cellRenderer: currencyCellRenderer,
                    valueGetter: statsFactory.revenueValueGetter,
                    suppressMenu: true
                }, {
                    headerName: 'Earnings per Click',
                    cellRenderer: currencyEpcRenderer,
                    valueGetter: statsFactory.epcValueGetter,
                    suppressMenu: true
                }
            ];
        }

        /**
         * @ngdoc component
         *
         * @description returns currency value of input value based on selected currency
         */
        function currencyCellRenderer(params) {
            var value = params.value ? params.value : 0;
            value = parseFloat(value) * parseFloat(vm.currency.conversion_rate);

            return "<span>" + statsFactory.formatCurrencyValue(vm.currency.name, value, decimalPlaces) + "</span>";
        }

        /**
         * @ngdoc component
         *
         * @description returns EPC currency value of input value based on selected currency
         */
        function currencyEpcRenderer(params) {
            if (params.data.clicks === 0) {
                var epc = 0;
            } else {
                var epc = parseFloat(params.data.credited_cost / params.data.clicks);
            }

            epc = parseFloat(epc) * parseFloat(vm.currency.conversion_rate);

            return "<span>" + statsFactory.formatCurrencyValue(vm.currency.name, epc, decimalPlaces) + "</span>";
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         *
         * @description filters the list of affiliate campaigns to be only affiliate campaigns
         */
        function filterAffiliateCampaigns() {
            vm.campaigns = vm.campaigns.filter(function (campaign) {
                return 'affiliate_camp_id' in campaign;
            });
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         *
         * @description gets a list of parameters for daily stats request
         * @returns {Array}
         */
        function getDailyRequestList() {
            var startDate = vm.datePicker.date.startDate;
            var endDate = vm.datePicker.date.endDate;
            var differentDays = moment(endDate).diff(startDate, 'days');
            var currentDate = moment(startDate);
            var paramList = [];

            vm.loading = true;
            vm.dataQueried = true;

            for (var i = 0; i < differentDays + 1; i++) {
                var dates = {
                    startDate: currentDate.format('L'),
                    endDate: currentDate.format('L')
                };

                paramList.push(dates);

                currentDate.add(1, 'days');
            }

            return paramList;
        }

        /**
         *
         * @param params
         * @param createTask
         * @param promise
         * @returns {promise.promise}
         */
        function queryDailyStats(params, createTask, promise) {
            if (createTask) {
                statsFactory.createStatsTask(params)
                    .then(function (response) {
                        if (response.data.meta.bro.isComplete) {
                            promise.resolve(response.data.data);
                        } else {
                            setTimeout(function () {
                                queryDailyStats(response.data.meta.bro.taskId, false, promise);
                            }, 2000);
                        }
                    })
            } else {
                statsFactory.getStats(params)
                    .then(function (response) {
                        if (response.data.meta.bro.isComplete) {
                            promise.resolve(response.data.data);
                        } else {
                            setTimeout(function () {
                                queryDailyStats(response.data.meta.bro.taskId, false, promise);
                            }, 2000);
                        }
                    })
            }

            return promise.promise;
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         *
         * @description gets the daily stats of each campaign
         */
        function getDailyStats() {
            var valid = verifyDailyStats();

            if (!valid) {
                return;
            }

            vm.loading = true;
            vm.dailyStats = [];
            vm.switchToggleText = 'Aggregate';

            var dailyRequestList = getDailyRequestList();
            var requests = [];

            for (var i = 0; i < dailyRequestList.length; i++) {
                var promise = $q.defer();
                requests.push(queryDailyStats(dailyRequestList[i], true, promise));
            }

            $q.all(requests)
                .then(function (response) {
                    handleDailyStatsResponse(response, dailyRequestList);
                })
                .catch(function (response) {
                    Notification.error({
                        message: 'Something went wrong getting your stats. Please try again in a few minutes.',
                        title: 'Uh oh'
                    });
                })
                .finally(function (response) {
                    vm.loading = false;
                })
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         * @description Verifys that the dates are valid to query
         * @returns {boolean}
         */
        function verifyDailyStats() {
            var startDate = vm.datePicker.date.startDate;
            var endDate = vm.datePicker.date.endDate;
            var differentDays = moment(endDate).diff(startDate, 'days');

            // Allow only a 14 day interval
            if (differentDays > 14) {
                Notification.warning({
                    message: 'There is a 14 day limit for getting daily stats. Please choose dates that fit that criteria.',
                    title: 'Warning'
                });
                return false;
            }

            if (!vm.campaign) {
                Notification.warning({
                    message: 'Please select a campaign to query data for.',
                    title: 'Warning'
                });
                return false;
            }

            return true;
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         * @description handles the response and converts to table data
         * @param response
         * @param requests
         */
        function handleDailyStatsResponse(response, requests) {
            var campaignId = vm.campaign.parent_campaign.camp_id;
            var campDataFound = false;

            for (var i = 0; i < response.length; i++) {
                if (!response[i].length) {
                    response[i] = statsFactory.defaultValues();
                } else {
                    for (var j = 0; j < response[i].length; j++) {
                        if (response[i][j].id === campaignId) {
                            response[i] = response[i][j];
                            campDataFound = true;
                            break;
                        }
                    }

                    // If data does not exist for this campaign id, set daily data to default values
                    if (!campDataFound) {
                        response[i] = statsFactory.defaultValues();
                    }
                    campDataFound = false;
                }

                response[i].date = requests[i].startDate;
            }

            setGridData(response);
            vm.pivotData = response;
            if (vm.gridOptions.api) {
                vm.gridOptions.api.sizeColumnsToFit();
            }
        }

        /**
         * @ngdoc component
         * @name ap.pivot
         *
         * @description Exports pivot table into a CSV
         */
        function exportCsv() {
            var fields = CsvFactory.pivotFields;
            var csvData = CsvFactory.createCsv(vm.pivotData, fields, vm.currency);

            return csvData;
        }
    }
})();
