(function() {
    angular
        .module('ap')
        .service('pendingRequests', pendingRequests)
        .service('http', http);

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    http.$inject = ['$http', '$q', 'pendingRequests'];

    /**
     * @ngdoc service
     * @name ap
     * @description service that handles the requests being sent to the API
     */
    function pendingRequests() {
        var pending = [];

        // Returns the pending array
        this.get = function () {
            return pending;
        };

        // Overwrite the existing array
        this.set = function(newArray) {
            pending = newArray;
            return pending;
        };

        // Adds to the pending array
        this.add = function (request) {
            pending.push(request);
        };

        // Remove from the pending array
        this.remove = function (request) {
            pending = _.filter(pending, function (p) {
                return p.url !== request;
            });
        };

        // Removes the promise
        this.removePromise = function(promise) {
            pending = _.filter(pending, function (p) {
                return p !== promise;
            });
        };

        // Cancels all requests
        this.cancelAll = function () {
            angular.forEach(pending, function (p) {
                if (p.hasOwnProperty('canceller')) {
                    p.canceller.resolve();
                } else if (p.hasOwnProperty('$cancelRequest')) {
                    p.$cancelRequest();
                }
            });
            pending.length = 0;
        };
    }

    /**
     * @ngdoc service
     * @name ap
     * @description service that handles the with pending requests
     * @param $http
     * @param $q
     * @param pendingRequests
     */
    function http($http, $q, pendingRequests) {
        this.get = getData;
        this.post = $http.post;
        this.put = $http.put;
        this.postCancelOnRouteChange = postData;

        this.resolveDataRequests = function(deferList) {
            // Accepts an array of functions or requests and resolves them in all.
            var deferred = $q.defer();
            return $q.all(deferList);
        };

        this.cancelAll = pendingRequests.cancelAll;

        /**
         * @ngdoc service
         * @name ap
         * @descriptionWrapper for GET that adds cancellable functionality
         * @param url
         * @param params
         * @returns {*}
         */
        function getData(url, params) {
            var canceller = $q.defer();
            var requestPromise;

            pendingRequests.add({
                url: url,
                canceller: canceller
            });

            if (params) {
                var extendedParams = angular.extend(params, {timeout: canceller.promise});
                //Request gets cancelled if the timeout-promise is resolved
                requestPromise = $http.get(url, extendedParams);
            } else {
                requestPromise = $http.get(url, {timeout: canceller.promise});
            }

            //Once a request has failed or succeeded, remove it from the pending list
            requestPromise.finally(function () {
                pendingRequests.remove(url);
            });
            return requestPromise;
        }

        /**
         * @ngdoc service
         * @name ap
         * @description Wrapper for POST that will cancel on route change
         *
         * We ONLY want to use this when we're posting data to GET data (ie. lists)
         * Otherwise, use $http.post or httpService.post
         * @param url
         * @param data
         * @param config
         * @returns {*}
         */
        function postData(url, data, config) {
            var canceller = $q.defer();
            var requestPromise;

            pendingRequests.add({
                url: url,
                canceller: canceller
            });

            if (config) {
                var extendedConfig = angular.extend(config, {timeout: canceller.promise});
                //Request gets cancelled if the timeout-promise is resolved
                requestPromise = $http.post(url, data, extendedConfig);
            } else {
                requestPromise = $http.post(url, data, {timeout: canceller.promise});
            }

            // Once a request has failed or succeeded, remove it from the pending list
            requestPromise.finally(function () {
                pendingRequests.remove(url);
            });
            return requestPromise;
        }
    }

})();

