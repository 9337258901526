(function() {
    'use strict';

    agGrid.initialiseAgGridWithAngular1(angular);

    angular
        .module('ap', [
            'ui.router',
            'agGrid',
            'ui.bootstrap',
            'daterangepicker',
            'ngclipboard',
            'ui-notification',
            'ui.select',
            'ngSanitize',
            'ui.checkbox',
            'ap.constants',
            'ngCsv',
            'oi.select'
        ])
        .config(routeConfig)
        .config(mainConfig)
        .config(uiSelectConfig)
        .filter('percent', ['$filter', percent])
        .constant('_', window._);

    /**
     * @ngdoc routeConfig
     * @name ap
     * @description confguration for routing in the angular app
     * @param $stateProvider
     */
    function routeConfig($stateProvider) {
        $stateProvider
            .state('ap', {
                abstract: true,
                url: "/app",
                templateUrl: '/views/index.html'
            })
                .state('ap.stats', {
                    url: '/stats',
                    templateUrl: '/views/stats.html'
                })
                .state('ap.pivot', {
                    url: '/pivot',
                    templateUrl: '/views/pivot.html'
                })
                .state('ap.token', {
                    url: '/token',
                    templateUrl: '/views/token.html'
                })
                .state('ap.affiliateCampaigns', {
                    url: '/affiliate_campaigns',
                    templateUrl: '/views/campaigns.affiliates.html'
                })
                .state('ap.publicCampaigns', {
                    url: '/public_campaigns',
                    templateUrl: '/views/campaigns.public.html'
                })
                .state('ap.account', {
                    url:'/account',
                    templateUrl: '/views/account.html'
                })
                .state('ap.email', {
                    url: '/email',
                    templateUrl: '/views/email.html'
                })
    }

    /**
     * @ngdoc mainConfig
     * @name ap
     * @description location configuration and urls
     * @param $locationProvider
     * @param $urlRouterProvider
     */
    function mainConfig($locationProvider, $urlRouterProvider) {
        // remove hash from URLs
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });

        $urlRouterProvider.otherwise(function ($injector) {
            var $state = $injector.get("$state");
            $state.go("ap.stats");
        });
    }

    /**
     * @ngdoc UiSelectConfig
     * @name ap
     * @param uiSelectConfig
     * @returns {Function}
     */
    function uiSelectConfig(uiSelectConfig) {
        uiSelectConfig.theme = 'bootstrap';
    }

    /**
     * @ngdoc percent
     * @name ap
     * @description percent filter
     * @param $filter
     * @returns {Function}
     */
    function percent($filter) {
        return function (input, symbol, decimals) {
            return $filter('number')(input * 100, decimals) + '%';
        };
    }

})();

