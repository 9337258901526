(function() {
    'use strict';

    angular
        .module('ap')
        .component('statsTable', statsTable());

    /**
     * @ngdoc statsTable
     * @name ap.stats
     * @description compnent for the stats table
     * @returns {{controller: StatsController, controllerAs: string, templateUrl: string}}
     */
    function statsTable() {
        return {
            controller: StatsController,
            controllerAs: 'vm',
            templateUrl: '/views/stats.table.html'
        };
    }

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    StatsController.$inject = ['$q', '_', 'statsFactory', 'DateFactory', 'ComputationFactory', '$uibModal', 'CsvFactory', 'Notification', 'sideMenuFactory'];

    /**
     * @ngdoc StatsController
     * @name ap.stats
     * @description controller for the stats table
     * @param $q
     * @param _
     * @param statsFactory
     * @param DateFactory
     * @param ComputationFactory
     * @param $uibModal
     * @param CsvFactory
     * @param Notification
     * @param sideMenuFactory
     * @constructor
     */
    function StatsController($q, _, statsFactory, DateFactory, ComputationFactory, $uibModal, CsvFactory, Notification, sideMenuFactory) {
        var vm = this;
        var rowHeight = 35;
        const decimalPlaces = 6;

        vm.tooltip = 'This page has the statistics for all your campaigns with the current date selected. ' +
            'To choose a different range of stats, select a new date range and click the \'Get Stats\' button on the top right of this page';
        vm.datePicker = {};
        vm.pageSize = 15;
        vm.loading = true;

        vm.datePicker.date = DateFactory.currentDate()
        vm.dateOptions = DateFactory.dateOptions();

        vm.exportCsv = exportCsv;
        vm.statsCsvHeaders = CsvFactory.statsHeaders;

        vm.onFilterChange = onFilterChange;
        vm.getStats = initDependencies;

        // Set default currency to USD
        vm.currency = {
            name: 'USD',
            conversion_rate: '1.0000000'
        };

        init();

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description begins the page
         */
        function init() {
            // Check env for show notifications flag
            sideMenuFactory.getEnvVariables()
                .then(function (response) {
                    if (response.data.currency) {
                        vm.currency = response.data.currency;
                    }

                    if (response.data.show_notifications) {
                        showNotifications();
                    }

                    initDependencies();
                });
            
            vm.getHeight = ((vm.pageSize + 1) * rowHeight) + 53 + 'px';
            vm.gridOptions = getGridOptions();
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description show notification modals to affiliate
         */
        function showNotifications() {
            statsFactory.checkForNotifications()
                .then(function (response) {
                    // Email Suppression CSV modal notification
                    if (response.data.data.suppression != 'Pass') {
                        $uibModal.open({
                            controller: function ($uibModalInstance) {
                                var vm = this;
                                vm.uploadedDate = response.data.data.suppression.created_at;

                                vm.accept = function () {
                                    // Create suppression log and sends download file to user
                                    statsFactory.createSuppressionLog(response.data.data.suppression.id);

                                    vm.close();
                                };

                                vm.close = function () {
                                    $uibModalInstance.close();
                                };
                            },
                            controllerAs: 'vm',
                            size: 'md',
                            backdrop : 'static',
                            templateUrl: '/views/modals/suppression.notice.html'
                        });
                    }

                    // Terms and Conditions modal notification, will appear over other modal
                    if (response.data.data.terms != 'Pass') {
                        $uibModal.open({
                            controller: function ($uibModalInstance) {
                                var vm = this;
                                vm.termsLink = response.data.data.terms;
                                vm.termsViewed = false;

                                vm.accept = function () {
                                    statsFactory.setTermsAccepted()
                                        .then(function (response) {
                                            $uibModalInstance.close();
                                        });
                                };
                            },
                            controllerAs: 'vm',
                            size: 'md',
                            backdrop: 'static',
                            keyboard: false,
                            templateUrl: '/views/modals/terms.notice.html'
                        });
                    }
                })
                .finally(function (response) {

                });
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description gets stats
         */
        function initDependencies() {
            vm.loading = true;

            var startDate = vm.datePicker.date.startDate;
            var endDate = vm.datePicker.date.endDate;

            var dates = {
                startDate: moment(startDate).format('L'),
                endDate: moment(endDate).format('L')
            };

            // Check whether dates is empty
            // If dates is empty, we show warning message like "Please select date." and return.
            if (dates.endDate === 'Invalid date' || dates.startDate === 'Invalid date') {
                Notification.warning({
                    message: 'Please select date.',
                    title: 'Warning'
                });
                vm.loading = false;
                return ;
            }

            var promises = [
                statsFactory.createStatsTask(dates)
            ];

            $q.all(promises)
                .then(function (response) {
                    if (response[0].data.meta.bro.isComplete) {
                        vm.stats = response[0].data.data;

                        if (vm.stats.constructor === Array) {
                            setGridData(vm.stats);
                        } else {
                            setGridData([]);
                        }

                        vm.gridOptions.api.sizeColumnsToFit();
                        vm.loading = false;
                        onFilterChange();
                    } else {
                        setGridData([]);
                        setTimeout(function() {
                            pollResults(response[0]);
                        }, 5000);
                    }
                })
                .catch(function (response) {
                })
                .finally(function (response) {
                });
        }

        /**
         * @ngdoc component
         * @name ap.stats
         * @param dataArray
         * @description sets the table data
         */
        function setGridData(dataArray) {
            if (dataArray === null) {
                return;
            }
            var numRows = dataArray.length;
            var totalRow = calculateGridTotalRow(dataArray);
            vm.gridOptions.api.setRowData(dataArray);
            vm.gridOptions.api.setFloatingBottomRowData([totalRow]);
            if (numRows === 0) {
                vm.getHeight = updateGridHeight(2);
            } else {
                vm.getHeight = updateGridHeight(numRows);
            }
            vm.gridOptions.api.sizeColumnsToFit();
        }

        /**
         * @ngdoc component
         * @name ap.stats
         * @param numRows
         * @description Updates the height of the grid (assuming min and max heights)
         */
        function updateGridHeight(numRows) {
            var maxGridHeight = 600;
            var minGridHeight = 100;

            var newHeight = (numRows * rowHeight) + (rowHeight * 2 );

            if (newHeight > maxGridHeight) {
                newHeight = maxGridHeight;
            } else if (newHeight < minGridHeight) {
                newHeight = minGridHeight;
            }
            return newHeight + 'px';
        }

        /**
         * @ngdoc component
         * @name ap.stats
         * @param dataArray
         * @description Creates summed total object for total row / group by date row
         */
        function calculateGridTotalRow(dataArray) {
            var totalRow = ComputationFactory.getPivotStatsTotal(dataArray);
            totalRow.camp_id = "Total";
            totalRow.name = '';
            return totalRow;
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description search filter for the table
         */
        function onFilterChange() {
            setGridData(vm.stats);
            vm.gridOptions.api.setQuickFilter(vm.campaignFilter);
            if (vm.gridOptions.api.rowModel.rowsToDisplay.length === 0) {
                setGridData([]);
            }
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description sets the grid options for ag-grid
         */
        function getGridOptions() {
            var columnDefs = getColumnDefs();

            return {
                headerHeight: rowHeight,
                colWidth: 170,
                columnDefs: columnDefs,
                rowHeight: rowHeight,
                rowSelection: 'single',
                rowData: null,
                enableSorting: true,
                enableFilter: true,
                angularCompileRows: true
            };
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description gets array of the columns on the campaigns for
         * ag-grid table
         */
        function getColumnDefs() {
            return [
                {
                    headerName: 'Campaign ID',
                    field: 'camp_id',
                    minWidth:80,
                    suppressMenu: true
                }, {
                    headerName: 'Campaign Name',
                    cellRenderer: statsFactory.nameRenderer,
                    valueGetter: statsFactory.nameRenderer,
                    minWidth:80,
                    suppressMenu: true
                }, {
                    headerName: 'Clicks',
                    field: 'clicks',
                    minWidth:80,
                    suppressMenu: true
                }, {
                    headerName: 'Leads',
                    field: 'credited_leads',
                    minWidth:80,
                    suppressMenu: true
                }, {
                    headerName: 'Revenue',
                    cellRenderer: currencyCellRenderer,
                    valueGetter: statsFactory.revenueValueGetter,
                    minWidth:80,
                    suppressMenu: true
                }, {
                    headerName: 'Earnings per Click',
                    cellRenderer: currencyEpcRenderer,
                    valueGetter: statsFactory.epcValueGetter,
                    minWidth:80,
                    suppressMenu: true
                }
            ];
        }

        /**
         * @ngdoc component
         *
         * @description returns currency value of input value based on selected currency
         */
        function currencyCellRenderer(params) {
            var value = params.value ? params.value : 0;
            value = parseFloat(value) * parseFloat(vm.currency.conversion_rate);

            return "<span>" + statsFactory.formatCurrencyValue(vm.currency.name, value, decimalPlaces) + "</span>";
        }

        /**
         * @ngdoc component
         *
         * @description returns EPC currency value of input value based on selected currency
         */
        function currencyEpcRenderer(params) {
            if (params.data.clicks === 0) {
                var epc = 0;
            } else {
                var epc = parseFloat(params.data.credited_cost / params.data.clicks);
            }

            epc = parseFloat(epc) * parseFloat(vm.currency.conversion_rate);

            return "<span>" + statsFactory.formatCurrencyValue(vm.currency.name, epc, decimalPlaces) + "</span>";
        }

        /**
         * @ngdoc component
         * @name ap.stats
         * @param response
         * @description recursively polls the bro client until all the data is returned
         */
        function pollResults(response) {
            if (response.data.meta.bro.isComplete) {
                vm.stats = response.data.data;

                if (vm.stats.constructor === Array) {
                    setGridData(vm.stats);
                } else {
                    setGridData([]);
                }

                vm.gridOptions.api.sizeColumnsToFit();
                vm.loading = false;
            } else {
                var taskId = response.data.meta.bro.taskId;

                statsFactory.getStats(taskId)
                    .then(function (response) {
                        setTimeout(function() {
                            pollResults(response);
                        }, 1000);
                    })
            }
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description Exports statistics table into a CSV
         */
        function exportCsv() {
            var fields = CsvFactory.statsFields;
            var csvData = CsvFactory.createCsv(vm.stats, fields, vm.currency);

            return csvData;
        }
    }
})();
