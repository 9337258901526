(function() {
    angular
        .module('ap')
        .factory('ComputationFactory', ComputationFactory);

    function ComputationFactory() {
        var computationFactoryExposed = {
            getStats: getStats,
            getSubtotalStats: getSubtotalStats,
            getPivotStatsSubtotal: getPivotStatsSubtotal,
            getPivotStatsTotal: getPivotStatsTotal
        };

        var baseKeys = [
            'clicks',
            'lp_views',
            'lp_clicks',
            'leads',
            'cost',
            'revenue',
            'credited_leads',
            'credited_cost'
        ];

        return computationFactoryExposed;

        // set base stats to 0
        function resetBase(stats) {
            angular.forEach(baseKeys, function(value) {
                stats[value] = 0;
            }, stats);

            return stats;
        }

        function setBase(baseStats, stats) {
            angular.forEach(baseKeys, function(value) {
                stats[value] = baseStats[value];
            }, stats);

            return stats;
        }

        function aggregateBaseStats(arrayOfObjects, stats) {
            for (var i = 0; i < arrayOfObjects.length; i++) {

                angular.forEach(baseKeys, function (value) {
                    if (value in stats) {
                        stats[value] += isNaN(arrayOfObjects[i][value]) ? 0 : parseFloat(arrayOfObjects[i][value]);
                    } else {
                        stats[value] = isNaN(arrayOfObjects[i][value]) ? 0 : parseFloat(arrayOfObjects[i][value]);
                    }
                }, stats);
            }

            return stats;
        }

        function getSubtotalStats(currentPageInfo) {
            var subtotalStats = {};
            subtotalStats = resetBase(subtotalStats);

            // pass an array of objects to reduce base stats
            subtotalStats = aggregateBaseStats(currentPageInfo, subtotalStats);

            subtotalStats.profit = getProfit(subtotalStats);
            subtotalStats.roi = getRoi(subtotalStats);
            subtotalStats.cpc = getCpc(subtotalStats);
            subtotalStats.ctr = getCtr(subtotalStats);
            subtotalStats.conversion = getConversion(subtotalStats);
            subtotalStats.avg_ml_lift = getAvgMlLift(currentPageInfo);
            subtotalStats.epc = getEpc(subtotalStats);
            subtotalStats.cv = getCv(subtotalStats);
            subtotalStats.lp_ctr = getLpCtr(subtotalStats);
            subtotalStats.epv = getEpv(subtotalStats);
            subtotalStats.mr = getMr(subtotalStats);
            subtotalStats.profit_per_click = getProfitPerClick(subtotalStats);
            subtotalStats.credited_profit_per_click = getCreditedProfitPerClick(subtotalStats);
            subtotalStats.credited_profit = getCreditedProfit(subtotalStats);
            subtotalStats.credited_roi = getCreditedRoi(subtotalStats);
            return subtotalStats;
        }

        function getStats(baseStats) {
            var stats = {};
            stats = resetBase(stats);

            // pass an object to set base stats
            stats = setBase(baseStats, stats);

            stats.profit = getProfit(stats);
            stats.roi = getRoi(stats);
            stats.cpc = getCpc(stats);
            stats.ctr = getCtr(stats);
            stats.conversion = getConversion(stats);
            stats.avg_ml_lift = getAvgMlLift(baseStats);
            stats.epc = getEpc(stats);
            stats.cv = getCv(stats);
            stats.lp_ctr = getLpCtr(stats);
            stats.epv = getEpv(stats);
            stats.mr = getMr(stats);
            stats.profit_per_click = getProfitPerClick(stats);
            stats.credited_profit_per_click = getCreditedProfitPerClick(stats);
            stats.credited_profit = getCreditedProfit(stats);
            stats.credited_roi = getCreditedRoi(stats);
            return stats;
        }

        function getPivotStatsSubtotal(original, children) {
            var subtotalStats = {};

            subtotalStats = resetBase(subtotalStats);

            subtotalStats = aggregateBaseStats(children, subtotalStats);

            original.profit = getProfit(subtotalStats);
            original.roi = getRoi(subtotalStats);
            original.cpc = getCpc(subtotalStats);
            original.ctr = getCtr(subtotalStats);
            original.conversion = getConversion(subtotalStats);
            original.epc = getEpc(subtotalStats);
            original.cv = getCv(subtotalStats);
            original.credited_profit = getCreditedProfit(subtotalStats);
            original.credited_roi = getCreditedRoi(subtotalStats);

            original = copySubtotal(original, subtotalStats);

            return original;
        }

        function copySubtotal(original, subtotal) {
            angular.forEach(baseKeys, function(value) {
                original[value] = subtotal[value];
            }, original);

            return original;
        }

        function getPivotStatsTotal(arrayOfObjects) {
            var subtotalStats = {};
            subtotalStats = resetBase(subtotalStats);
            subtotalStats = aggregateBaseStats(arrayOfObjects, subtotalStats);

            subtotalStats.profit = getProfit(subtotalStats);
            subtotalStats.roi = getRoi(subtotalStats);
            subtotalStats.cpc = getCpc(subtotalStats);
            subtotalStats.ctr = getCtr(subtotalStats);
            subtotalStats.conversion = getConversion(subtotalStats);
            subtotalStats.epc = getEpc(subtotalStats);
            subtotalStats.cv = getCv(subtotalStats);
            subtotalStats.credited_profit = getCreditedProfit(subtotalStats);
            subtotalStats.credited_roi = getCreditedRoi(subtotalStats);

            return subtotalStats;
        }


        // Profit
        function getProfit(inputStats) {
            // profit = revenue - cost;
            return (inputStats.revenue - inputStats.cost);
        }

        // Return on investment
        function getRoi(inputStats) {
            if (inputStats.cost === 0) { return 0; }
            return (getProfit(inputStats))/inputStats.cost * 100;
        }

        // Cost per click
        function getCpc(inputStats) {
            // cpc = cost / clicks
            if (inputStats.clicks === 0) { return 0; }
            return inputStats.cost / inputStats.clicks;
        }

        // Click through rate
        function getCtr(inputStats) {
            // ctr = lp_clicks / clicks
            if (inputStats.clicks === 0) { return 0; }
            return (inputStats.lp_clicks / inputStats.clicks) * 100;
        }

        // Conversion rate
        function getConversion(inputStats) {
            // conversion = leads/ clicks;
            if (inputStats.clicks === 0) { return 0; }
            return (inputStats.leads/inputStats.clicks) * 100;
        }

        // Avg. ML Lift
        function getAvgMlLift(inputStats) {
            if (!angular.isArray(inputStats)) {
                return 0;
            }
            var avgMlLiftStats = inputStats
                .filter(function (item) {
                    return angular.isNumber(item.avg_ml_lift);
                })
                .map(function (item) {
                    return item.avg_ml_lift;
                });

            if (avgMlLiftStats.length === 0) {
                return 0;
            }

            return avgMlLiftStats.reduce(function(a, b) { return a + b; }) / avgMlLiftStats.length;
        }

        // Earnings per click
        function getEpc(inputStats) {
            if(inputStats.epc) {return inputStats.epc;}
            if (inputStats.clicks === 0) { return 0; }
            return inputStats.revenue / inputStats.clicks;
        }

        // Conversions per view
        function getCv(inputStats) {
            // leads / lp_views
            if (inputStats.lp_views === 0) { return 0;}
            return inputStats.leads / inputStats.lp_views * 100;
        }

        // Landing page click through rate
        function getLpCtr(inputStats) {
            // LP_CTR = lp_clicks / lp_views
            if (inputStats.lp_views === 0) { return 0; }
            return inputStats.lp_clicks / inputStats.lp_views * 100;
        }

        // Earnings per view
        function getEpv(inputStats) {
            // revenue / lp_views
            if (inputStats.lp_views === 0) { return 0; }
            return inputStats.revenue / inputStats.lp_views;
        }

        function getMr(inputStats) {
            // (leads - credited_leads) / leads
            if (inputStats.leads === 0) { return 0; }
            return (inputStats.leads - inputStats.credited_leads) / inputStats.leads * 100;
        }

        function getProfitPerClick(inputStats) {
            if (inputStats.clicks < 1 || getProfit(inputStats) === 0) return 0;
            return getProfit(inputStats) / inputStats.clicks;
        }

        function getCreditedProfit(inputStats) {
            return inputStats.revenue - inputStats.credited_cost;
        }

        function getCreditedRoi(inputStats) {
            if (inputStats.credited_cost === 0) { return 0; }
            return getCreditedProfit(inputStats) / inputStats.credited_cost * 100;
        }

        function getCreditedProfitPerClick(inputStats) {
            if (inputStats.clicks < 1 || getCreditedProfit(inputStats) === 0) return 0;
            return getCreditedProfit(inputStats) / inputStats.clicks;
        }
    }
})();

