(function() {
    angular
        .module('ap')
        .factory('DateFactory', DateFactory);

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    DateFactory.$inject = [];

    /**
     * @ngdoc factory
     * @name ap
     * @description validations on the front end
     */
    function DateFactory() {
        var factory = {
            currentDate: currentDate,
            dateOptions: dateOptions
        };

        /**
         * @ngdoc factory
         * @name ap
         * @description current date as object
         */
        function currentDate() {
            return {
                startDate: moment(),
                endDate: moment()
            }
        }

        /**
         * @ngdoc factory
         * @name ap
         * @description default date options
         * @returns {{locale: {applyClass: string, applyLabel: string, fromLabel: string, format: string,
         * toLabel: string, cancelLabel: string, customRangeLabel: string}, ranges: {Today: *[], Yesterday: *[],
         * Last 7 Days: *[], Last 30 Days: *[]}}}
         */
        function dateOptions() {
            return {
                locale: {
                    applyClass: 'btn-green',
                        applyLabel: "Select",
                        fromLabel: "From",
                        format: "MMMM Do YYYY",
                        toLabel: "To",
                        cancelLabel: 'Cancel',
                        customRangeLabel: 'Custom Date'
                },
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()]
                },
                maxDate: moment()
            }
        }

        return factory;
    }
})();

