(function() {
    'use strict';

    angular
        .module('ap')
        .factory('agGridHelperFactory', agGridHelperFactory);


    function agGridHelperFactory() {
        var factory = {
            DebouncedTextFilter: DebouncedTextFilter,
            autoSizeColumnsToFitGridData: autoSizeColumnsToFitGridData,
            autoSizeColumnsToFitGridWidth: autoSizeColumnsToFitGridWidth,
            autoSizeColumnsToFitGridHeader: autoSizeColumnsToFitGridHeader,
            resizeColumns: resizeColumns,
            updateGridColumn: updateGridColumn,
            toggleAllColumns: toggleAllColumns,
            setGridLoading: setGridLoading,
            sortNumbers: sortNumbers,
            sortFloat: sortFloat
        };

        return factory;

        /**
         *
         * @returns {{}}
         * @constructor
         */
        function DebouncedTextFilter() {
            var exposed = {};

            exposed.valueGetter = null;
            exposed.onFloatingFilterChanged = null;
            exposed.currentParentModel = null;
            exposed.params = null;
            exposed.eGui = null;
            exposed.currentValue = null;
            exposed.eFilterInput = null;

            exposed.init = init;
            exposed.onParentModelChanged = onParentModelChanged;
            exposed.getGui = getGui;

            return exposed;

            /**
             * @ngdoc method
             * @description Init function for custom filter
             * @param params - Column params
             */
            function init(params) {
                var debounceTime = 500;
                var useApplyButton = false;

                // callback on filter changed
                exposed.valueGetter = params.valueGetter;
                exposed.onFloatingFilterChanged = params.onFloatingFilterChanged;
                exposed.currentParentModel = params.currentParentModel;
                exposed.params = params;

                // create the filter input element
                exposed.eGui = document.createElement('div');
                exposed.eGui.innerHTML = '<input type="text" class="form-control tr-input text-center" placeholder="Filter">';

                // init filter value to null
                exposed.currentValue = null;

                // get a DOM reference to the filter input
                exposed.eFilterInput = exposed.eGui.querySelector('input');

                /**
                 * @ngdoc method
                 * @description Debounced init function
                 * @param params - Column params
                 */
                var onInputBoxChangedDebounced = _.debounce(function (e) {
                    if (exposed.eFilterInput.value === '') {
                        // If the input box is empty we clear the filter
                        exposed.onFloatingFilterChanged({
                            model: {
                                type: 'contains',
                                filter: null
                            },
                            apply: useApplyButton
                        });
                        return;
                    }

                    // filter has a value
                    exposed.currentValue = exposed.eFilterInput.value;
                    exposed.onFloatingFilterChanged({
                        model: {
                            type: 'contains',
                            filter: exposed.currentValue
                        },
                        apply: useApplyButton
                    });
                }, debounceTime);

                // event listener add
                exposed.eFilterInput.addEventListener('input', onInputBoxChangedDebounced);
            }

            /**
             * @ngdoc method
             * @description Callback when parent changes
             * @param parentModel - Ag Grid param
             * @returns {Element|*}
             */
            function onParentModelChanged(parentModel) {
                // When the filter is empty we will receive a null message here
                if (!parentModel) {
                    exposed.eFilterInput.value = '';
                    exposed.currentValue = null;
                } else {
                    exposed.eFilterInput.value = parentModel.filter + '';
                    exposed.currentValue = parentModel.filter;
                }
            }

            /**
             * @ngdoc method
             * @description Returns gui DOM element
             * @returns {Element|*}
             */
            function getGui() {
                return exposed.eGui;
            }
        }

        /**
         * Comparator function to use to sort columns (numerical)
         * @param a
         * @param b
         * @returns {number}
         */
        function sortNumbers(a,b) {
            var numA = parseInt(a);
            var numB = parseInt(b);

            if (numA > numB) {
                return 1;
            } else if (numA < numB) {
                return -1;
            } else {
                return 0;
            }
        }

        /**
         * Comparator function to use to sort columns (float number)
         * @param a
         * @param b
         * @returns {number}
         */
        function sortFloat(a,b) {
            var numA = parseFloat(a);
            var numB = parseFloat(b);

            if (isNaN(numB) && isNaN(numA)) {
                return 0;
            }

            if (numA > numB || isNaN(numB)) {
                return 1;
            } else if (numA < numB || isNaN(numA)) {
                return -1;
            } else {
                return 0;
            }
        }


        /**
         * AG-GRID: Set all loading variables for grid and page
         * @param value
         * @param loadingFlag
         * @param gridOptions
         */
        function setGridLoading(value, loadingFlag, gridOptions) {
            value = value || false;
            loadingFlag = value;

            if (value) {
                gridOptions.api.showLoadingOverlay();
            } else {
                gridOptions.api.hideOverlay();
            }
            return loadingFlag;
        }

        /**
         * AG-GRID: Controls - Show/hide single column
         */
        function updateGridColumn(columnDef, isColumnsFitHeader, gridOptions) {
            gridOptions.columnApi.setColumnVisible(columnDef.field, !columnDef.hide);
            resizeColumns(isColumnsFitHeader, gridOptions);
        }

        /**
         * AG-GRID: Controls - Show/hide all columns
         */
        function toggleAllColumns(selectAll, isColumnsFitHeader, gridOptions) {
            _.forEach(gridOptions.columnApi.getAllColumns(), function (value) {
                value.colDef.hide = !selectAll;
            });
            var fields = gridOptions.columnApi.getAllColumns().map(function (columnDef) {
                return columnDef.field;
            });
            gridOptions.columnApi.setColumnsVisible(fields, selectAll);
            resizeColumns(isColumnsFitHeader, gridOptions);
        }


        /**
         * AG-GRID:  Autosizes all columns to fit the length of the header name
         * @param gridOptions
         */
        function autoSizeColumnsToFitGridHeader(gridOptions) {
            gridOptions.columnApi.getAllColumns().forEach(function (columnDef) {
                var charsize = 8;
                var buffer = 12;
                var headerWidth = (columnDef.colDef.headerName.length * charsize);
                var newWidth = (columnDef.colDef.width > headerWidth) ? columnDef.colDef.width : headerWidth;

                gridOptions.columnApi.setColumnWidth(columnDef.colId, newWidth + buffer, true);
            });

        }

        /**
         * AG-GRID: Autosize all columns to fit their data
         */
        function autoSizeColumnsToFitGridData(gridOptions) {
            var allColumnIds = [];

            gridOptions.columnApi.getAllColumns().forEach(function (columnDef) {
                allColumnIds.push(columnDef.getColId());
            });
            gridOptions.columnApi.autoSizeColumns(allColumnIds);
        }

        /**
         * AG-GRID: Autosize columns to fit grid width
         */
        function autoSizeColumnsToFitGridWidth(gridOptions) {
            gridOptions.api.sizeColumnsToFit();
        }

        /**
         * Resize Grid Columns based on parameter flag
         */
        function resizeColumns(isColumnsFitHeader,gridOptions) {
            if (isColumnsFitHeader) {
                autoSizeColumnsToFitGridHeader(gridOptions);
            } else {
                autoSizeColumnsToFitGridData(gridOptions);
            }
        }

    }
})();
