(function() {
    'use strict';

    angular
        .module('ap')
        .component('sideMenu', sideMenu());

    /**
     * @ngdoc sideMenu
     * @name ap
     * @description compnent for the side menu
     * @returns {{controller: sideMenuController, controllerAs: string, templateUrl: string}}
     */
    function sideMenu() {
        return {
            controller: sideMenuController,
            controllerAs: 'vm',
            templateUrl: '../views/side-menu.html'
        }
    }

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    sideMenuController.$inject = [ '$state', 'sideMenuFactory'];

    /**
     * @ngdoc sideMenuController
     * @name ap
     * @description controller for the side menu
     * @param $state
     */
    function sideMenuController($state, sideMenuFactory) {
        var vm = this;
        sideMenuFactory.getEnvVariables()
            .then(function (response) {
                vm.subAffStats = response.data.sub_affiliate_stats;
                vm.customLinkTitle = response.data.custom_link_title;
                vm.customLinkUrl = response.data.custom_link_url;
            });
        vm.state = $state;
    }
})();
