(function () {
    'use strict';

    angular
        .module('ap')
        .component('tokenTable', tokenTable());

    /**
     * @ngdoc TokenTable
     * @name ap.token
     * @description compnent for the token table
     * @returns {{controller: TokenController, controllerAs: string, templateUrl: string}}
     */
    function tokenTable() {
        return {
            controller: TokenController,
            controllerAs: 'vm',
            templateUrl: '/views/token.table.html'
        };
    }

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    TokenController.$inject = [
        '$q', '_', 'statsFactory', 'campaignsFactory', 'DateFactory', 'ErrorFactory','ComputationFactory', 'sideMenuFactory', 'Notification', 'CsvFactory'
    ];

    /**
     * @ngdoc TokenController
     * @name ap.token
     * @description controller for the token table
     * @param $q
     * @param _
     * @param statsFactory
     * @param campaignsFactory
     * @param DateFactory
     * @param ErrorFactory
     * @param ComputationFactory
     * @param sideMenuFactory
     * @param Notification
     * @param CsvFactory
     * @constructor
     */
    function TokenController($q, _, statsFactory, campaignsFactory, DateFactory, ErrorFactory, ComputationFactory, sideMenuFactory, Notification, CsvFactory) {
        var vm = this;
        vm.tooltip = 'This page allows you to Sub Affiliate for each campaign to see their respective daily stats. Select a ' +
            'campaign and then a date to view their individual stats';

        var rowHeight = 35;
        const decimalPlaces = 6;
        vm.datePicker = {};
        vm.pageSize = 12;
        vm.loading = true;

        vm.datePicker.date = DateFactory.currentDate();
        vm.dateOptions = DateFactory.dateOptions();

        vm.exportCsv = exportCsv;
        vm.tokenCsvHeaders = CsvFactory.tokenHeaders;

        vm.onFilterChange = onFilterChange;
        vm.getStats = getTokenStats;

        // Set default currency to USD
        vm.currency = {
            name: 'USD',
            conversion_rate: '1.0000000'
        };

        init();

        /**
         * @ngdoc component
         * @name ap.token
         *
         * @description gets page dependencies
         */
        function initDependencies() {
            // Remove Last 30 days from datepicker
            delete vm.dateOptions.ranges['Last 30 Days'];

            var promises = {
                'campaigns': campaignsFactory.getAffiliateCampaigns(),
                'env': sideMenuFactory.getEnvVariables()
            };

            $q.all(promises)
                .then(function (response) {
                    vm.campaigns = response['campaigns'].data.data;

                    if (response['env'].data && response['env'].data.currency) {
                        // Overwrite default USD if currency is set for affiliate
                        vm.currency = response['env'].data.currency;
                    }

                    filterAffiliateCampaigns();
                })
                .catch(ErrorFactory.outputError)
                .finally(function (response) {
                    vm.loading = false;
                })
        }

        /**
         * @ngdoc component
         * @name ap.token
         *
         * @description begins the page
         */
        function init() {
            vm.getHeight = ((vm.pageSize + 1) * rowHeight) + 53 + 'px';
            vm.gridOptions = getGridOptions();
        }

        /**
         * @ngdoc component
         * @name ap.token
         *
         * @description sets the table data
         */
        function setGridData(dataArray) {
            if (dataArray === null) {
                return;
            }
            var numRows = dataArray.length;
            var totalRow = calculateGridTotalRow(dataArray);

            if (numRows === 0) {
                vm.getHeight = updateGridHeight(2);
            } else {
                vm.getHeight = updateGridHeight(numRows);
            }

            if (vm.gridOptions.api) {
                vm.gridOptions.api.setRowData(dataArray);
                vm.gridOptions.api.setFloatingBottomRowData([totalRow]);
                vm.gridOptions.api.sizeColumnsToFit();
            }
        }

        /**
         * @ngdoc component
         * @name ap.token
         * @param dataArray
         * @description Creates summed total object for total row / group by date row
         */
        function calculateGridTotalRow(dataArray) {
            var totalRow = ComputationFactory.getPivotStatsTotal(dataArray);
            totalRow.click_c10 = "Total";
            totalRow.name = '';
            return totalRow;
        }
        /**
         * @ngdoc component
         * @name ap.token
         *
         * @description search filter for the table
         */
        function onFilterChange() {
            vm.gridOptions.api.setQuickFilter(vm.filter);
            if (vm.gridOptions.api.rowModel.rowsToDisplay.length === 0) {
                setGridData([]);
            }
        }

        /**
         * @ngdoc component
         * @name ap.token
         * @param numRows
         * @description Updates the height of the grid (assuming min and max heights)
         */
        function updateGridHeight(numRows) {
            var maxGridHeight = 600;
            var minGridHeight = 100;

            var newHeight = (numRows * rowHeight) + (rowHeight * 2 );

            if (newHeight > maxGridHeight) {
                newHeight = maxGridHeight;
            } else if (newHeight < minGridHeight) {
                newHeight = minGridHeight;
            }
            return newHeight + 'px';
        }

        /**
         * @ngdoc component
         * @name ap.token
         *
         * @description sets the grid options for ag-grid
         */
        function getGridOptions() {
            var columnDefs = getColumnDefs();

            return {
                headerHeight: rowHeight,
                colWidth: 170,
                columnDefs: columnDefs,
                rowHeight: rowHeight,
                rowSelection: 'single',
                rowData: null,
                enableSorting: true,
                enableFilter: true,
                enableColResize: true,
                suppressMenuHide: true,
                suppressCellSelection: true,
                angularCompileRows: true,
                onGridReady: function (event) {
                    initDependencies();
                }
            };
        }

        /**
         * @ngdoc component
         * @name ap.token
         *
         * @description gets array of the columns on the campaigns for
         * ag-grid table
         */
        function getColumnDefs() {
            return [
                {
                    headerName: 'Sub Affiliate',
                    field: 'click_c10',
                    minWidth:80,
                    suppressMenu: true
                }, {
                    headerName: 'Clicks',
                    field: 'clicks',
                    minWidth:80,
                    suppressMenu: true
                }, {
                    headerName: 'Leads',
                    field: 'credited_leads',
                    minWidth:80,
                    suppressMenu: true
                }, {
                    headerName: 'Revenue',
                    cellRenderer: currencyCellRenderer,
                    valueGetter: statsFactory.revenueValueGetter,
                    minWidth:80,
                    suppressMenu: true
                }, {
                    headerName: 'Earnings per Click',
                    cellRenderer: currencyEpcRenderer,
                    valueGetter: statsFactory.epcValueGetter,
                    minWidth:80,
                    suppressMenu: true
                }
            ];
        }

        /**
         * @ngdoc component
         *
         * @description returns currency value of input value based on selected currency
         */
        function currencyCellRenderer(params) {
            var value = params.value ? params.value : 0;
            value = parseFloat(value) * parseFloat(vm.currency.conversion_rate);

            return "<span>" + statsFactory.formatCurrencyValue(vm.currency.name, value, decimalPlaces) + "</span>";
        }

        /**
         * @ngdoc component
         *
         * @description returns EPC currency value of input value based on selected currency
         */
        function currencyEpcRenderer(params) {
            if (params.data.clicks === 0) {
                var epc = 0;
            } else {
                var epc = parseFloat(params.data.credited_cost / params.data.clicks);
            }

            epc = parseFloat(epc) * parseFloat(vm.currency.conversion_rate);

            return "<span>" + statsFactory.formatCurrencyValue(vm.currency.name, epc, decimalPlaces) + "</span>";
        }

        /**
         * @ngdoc component
         * @name ap.token
         *
         * @description filters the list of affiliate campaigns to be only affiliate campaigns
         */
        function filterAffiliateCampaigns() {
            vm.campaigns = vm.campaigns.filter(function (campaign) {
                return 'affiliate_camp_id' in campaign;
            });
        }

        /**
         * @ngdoc component
         * @name ap.token
         *
         * @description gets the c10 token stats pivoted by affiliate ID and campaign ID
         */
        function getTokenStats() {
            if (!vm.campaign) {
                Notification.warning({
                    message: 'Please select a campaign to query data for.',
                    title: 'Warning'
                });
                return false;
            }

            vm.loading = true;
            vm.dataQueried = true;

            var startDate = vm.datePicker.date.startDate;
            var endDate = vm.datePicker.date.endDate;

            var params = {
                campId: vm.campaign.camp_id,
                startDate: moment(startDate).format('L'),
                endDate: moment(endDate).format('L')
            };

            // Check whether dates is empty
            // If dates is empty, we show warning message like "Please select date." and return.
            if (params.endDate === 'Invalid date' || params.startDate === 'Invalid date') {
                Notification.warning({
                    message: 'Please select date.',
                    title: 'Warning'
                });
                vm.loading = false;
                return;
            }

            var promises = [
                statsFactory.createSubAffiliatesTask(params)
            ];

            $q.all(promises)
                .then(function (response) {
                    if (response[0].data.meta.bro.isComplete) {
                        vm.tokenData = response[0].data.data;

                        if (vm.tokenData.constructor === Array) {
                            setGridData(vm.tokenData);
                        } else {
                            setGridData([]);
                        }

                        vm.loading = false;
                        onFilterChange();
                    } else {
                        setGridData([]);
                        setTimeout(function() {
                            pollResults(response[0]);
                        }, 5000);
                    }
                })
                .catch(function (response) {
                })
                .finally(function (response) {
                });
        }

        /**
         * @ngdoc component
         * @name ap.token
         * @param response
         * @description recursively polls the bro client until all the data is returned
         */
        function pollResults(response) {
            if (response.data.meta.bro.isComplete) {
                vm.tokenData = response.data.data;

                if (vm.tokenData.constructor === Array) {
                    setGridData(vm.tokenData);
                } else {
                    setGridData([]);
                }

                vm.loading = false;
            } else {
                var taskId = response.data.meta.bro.taskId;

                statsFactory.getStats(taskId)
                    .then(function (response) {
                        setTimeout(function() {
                            pollResults(response);
                        }, 1000);
                    })
            }
        }

        /**
         * @ngdoc component
         * @name ap.token
         *
         * @description Exports pivot table into a CSV
         */
        function exportCsv() {
            var fields = CsvFactory.tokenFields;
            var csvData = CsvFactory.createCsv(vm.tokenData, fields, vm.currency);

            return csvData;
        }
    }
})();
