(function () {
    'use strict';

    angular
        .module('ap')
        .factory('sideMenuFactory', sideMenuFactory);

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    sideMenuFactory.$inject = ['http'];

    /**
     * @ngdoc factory
     * @name ap.side-menu
     * @param http
     * @param $http
     */
    function sideMenuFactory(http) {
        var baseUri = '/api/v1/';

        var factory = {
            getEnvVariables: getEnvVariables
        };

        return factory;

        /**
         * @ngdoc Get Info
         * @name ap.side-menu
         * @description gets the SubAffiliate information
         */
        function getEnvVariables() {
            return http.get(baseUri + 'env');
        }
    }
})();
