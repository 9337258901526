(function () {
    'use strict';

    angular
        .module('ap')
        .factory('statsFactory', statsFactory);

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    statsFactory.$inject = ['http', '$filter'];

    /**
     * @ngdoc statsFactory
     * @name ap.stats
     * @description factory for stats
     * @param $http
     * @returns {{createStatsTask: createStatsTask, getStats: getStats}}
     */
    function statsFactory(http, $filter) {
        var baseUri = '/api/v1/';

        // Currency name to Language Tag
        const currencyLocaleMap = {
            'USD': 'en-US',     // American English
            'GBP': 'en-GB',     // British English
            'EUR': 'nl-NL',     // Standard Dutch
            'AUD': 'en-AU'      // Australian English
        };

        var factory = {
            createStatsTask: createStatsTask,
            createSubAffiliatesTask: createSubAffiliatesTask,
            getStats: getStats,
            defaultValues: defaultValues,
            nameRenderer: nameRenderer,
            epcRenderer: epcRenderer,
            epcValueGetter: epcValueGetter,
            revenueRenderer: revenueRenderer,
            revenueValueGetter: revenueValueGetter,
            checkForNotifications: checkForNotifications,
            setTermsAccepted: setTermsAccepted,
            createSuppressionLog: createSuppressionLog,
            formatCurrencyValue: formatCurrencyValue,
            currencyLocaleMap: currencyLocaleMap
        };

        return factory;

        /**
         * @ngdoc createStatsTask
         * @name ap.stats
         * @description creates a task to get stats
         * @param date
         */
        function createStatsTask(date) {
            return http.get(baseUri + 'stats', {
                params: {
                    startDate: date.startDate,
                    endDate: date.endDate
                }
            });
        }

        /**
         * @ngdoc createSubAffiliatesTask
         * @name ap.tokens
         * @description creates a task to get stats for Sub Affiliates
         * @param date
         */
        function createSubAffiliatesTask(params) {
            return http.get(baseUri + 'tokens', {
                params: {
                    campId: params.campId,
                    startDate: params.startDate,
                    endDate: params.endDate
                }
            });
        }

        /**
         * @ngdoc getStats
         * @name ap.stats
         * @description polling endpoing to get stats
         * @param taskId
         */
        function getStats(taskId) {
            return http.get(baseUri + 'result', {
                params: { taskId: taskId }
            });
        }

        /**
         * @ngdoc checkForNotifications
         * @name ap.stats
         * @description check for affiliate notifications
         */
        function checkForNotifications() {
            return http.get(baseUri + 'notifications');
        }

        /**
         * @ngdoc setTermsAccepted
         * @name ap.stats
         * @description set terms_accepted flag to true on affiliate entity
         */
        function setTermsAccepted() {
            return http.get(baseUri + 'terms-accept');
        }

        /**
         * @ngdoc createSuppressionLog
         * @name ap.stats
         * @description download csv file, create email suppression log for csvId and affiliate
         * @param csvId
         */
        function createSuppressionLog(csvId) {
            return window.open(baseUri + 'email-suppression/' + csvId, '_blank');
        }

        /**
         * @ngdoc defaultValues
         * @name ap.stats
         * @description default values to 0 for a campaign
         * @returns {{clicks: number, credited_leads: number, revenue: number}}
         */
        function defaultValues() {
            return {
                clicks: 0,
                credited_leads: 0,
                credited_cost: 0
            }
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description renders the campaign name column
         */
        function nameRenderer(params) {
            var campName = params.data.name;
            var prefix = params.data.camp_id.toString() + ' - ';

            if (campName && campName.includes(prefix)) {
                campName = params.data.name.replace(prefix, '');
            }

            return campName;
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description renders the epc column
         */
        function epcRenderer(params) {
            if (params.data.clicks === 0) {
                var epc = 0;
            } else {
                var epc = parseFloat(params.data.credited_cost / params.data.clicks);
            }

            return $filter('currency')(epc, '$', 6);
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description gets the value of the epc for sorting
         * @param params
         * @returns {number|Number}
         */
        function epcValueGetter(params) {
            if (params.data.clicks === 0) {
                var epc = 0;
            } else {
                var epc = parseFloat(params.data.credited_cost / params.data.clicks);
            }

            return epc;
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description renders the payout column
         */
        function revenueRenderer(params) {
            return $filter('currency')(params.data.credited_cost, '$', 6);
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description gets the value of the revenue for sorting
         */
        function revenueValueGetter(params) {
            return params.data.credited_cost;
        }

        /**
         * @ngdoc component
         * @name ap.stats
         *
         * @description returns string of currency formatted value
         * @returns {*}
         */
        function formatCurrencyValue(currencyName, value, decimalPlaces) {
            return value.toLocaleString(currencyLocaleMap[currencyName], {
                style: 'currency',
                currency: currencyName,
                minimumFractionDigits: decimalPlaces
            });
        }
    }
})();
