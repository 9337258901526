(function () {
    angular
        .module('ap')
        .factory('agGridConfig', agGridConfig);

    /**
     * @ngdoc agGridConfig
     * @name ap
     * @description helps setting up some ag-grid assets
     */
    function agGridConfig() {
        var loadingTemplate = [
            '<span class="ag-overlay-loading-center">',
            '<i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>',
            '<span class="sr-only">Loading...</span>',
            '</span>'
        ];

        return {
            colWidth: 65,
            rowHeight: 20,
            headerHeight: 20,
            rowModelType: null,
            rowData: null,
            enableFilter: true,
            enableColResize: true,
            suppressMenuHide: true,
            suppressCellSelection: true,
            rowSelection: 'single',
            overlayLoadingTemplate: loadingTemplate.join('')
        };
    }
})();