(function() {
    'use strict';

    angular
        .module('ap')
        .directive('apInput', apInput);

    /**
     * @ngdoc apInput directive
     * @name ap
     * @description directive for an input box for AP
     * @returns {{restrict: string, scope: {show: string}, transclude: boolean, template: string}}
     */
    function apInput() {
        return {
            restrict: 'AE',
            scope: {
                icon: '@',
                label: '@',
                type: '@',
                model: '=',
                readOnly: '='
            },
            transclude: true,
            template: "" +
            "<div class='form-group'>" +
                "<div class='row'>" +
                    "<div class='col-lg-12'>" +
                        "<label class='col-lg-2 m-t-xs'>{{ label }}</label>" +
                        "<div class='col-lg-4'>" +
                            "<div class='input-group'>" +
                                "<span class='input-group-addon ap-addon'><i class='glyphicon {{ icon }}'></i></span>" +
                                "<input class='form-control ap-input' type='{{ type }}' ng-model='model' ng-readonly='readOnly'>" +
                            "</div>" +
                        "</div>" +
                    "</div>" +
                "</div>" +
            "</div>"
        };
    }
})();
