(function () {
    'use strict';

    angular
        .module('ap')
        .factory('campaignsFactory', campaignsFactory);

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    campaignsFactory.$inject = ['http', '_', 'CONSTANTS'];

    /**
     * @ngdoc factory
     * @name ap.campaigns
     * @param http
     * @param _
     * @param CONSTANTS
     * @returns {{getCampaigns: getCampaigns}}
     */
    function campaignsFactory(http, _, CONSTANTS) {
        var baseUri = '/api/v1/';

        var countryCodeToNameMap = {};
        _.each(CONSTANTS.countries.ARRAY, function (country) {
            countryCodeToNameMap[country['code']] = country['name'];
        });

        var countryNameToCodeMap = {};
        _.each(CONSTANTS.countries.ARRAY, function (country) {
            countryNameToCodeMap[country['name'].toLowerCase()] = country['code'];
        });

        var factory = {
            getAffiliateCampaigns: getAffiliateCampaigns,
            getPublicCampaigns: getPublicCampaigns,
            createCampaign: createCampaign,
            updateCampaign: updateCampaign,
            getAllCampTags: getAllCampTags,
            getTagsByCampaign: getTagsByCampaign,
            countryCodesToNames: countryCodesToNames,
            countryNameToCode: countryNameToCode
        };

        return factory;

        /**
         * @ngdoc Get Info
         * @name ap.campaigns
         * @description gets the affiliate's campaign information
         */
        function getAffiliateCampaigns(filterTags) {
            var params = {
                filterTags: typeof filterTags === 'string' ? filterTags : null
            };

            return http.get(baseUri + 'campaigns/affiliate', {params: params});
        }

        /**
         * @ngdoc Get Info
         * @name ap.campaigns
         * @description gets the public campaign information
         */
        function getPublicCampaigns(parameters) {
            var params = {
                skip: parameters.startRow,
                limit: 1000,
                filterTags: typeof parameters.filterTags === 'string' ? parameters.filterTags : null
            };
            var allFilters = {};

            if (Object.keys(parameters.filterModel).length) {
                var filter;

                for (filter in parameters.filterModel) {
                    // Store each filter in object to pass to backend
                    var filterParam = {};
                    filterParam.name = filter;
                    filterParam.type = parameters.filterModel[filter].type;
                    filterParam.value = parameters.filterModel[filter].filter;

                    allFilters[filter] = filterParam;
                }

                params.filters = allFilters;
            }

            if (Object.keys(parameters.sortModel).length) {
                var sort;
                var sortParam = {};

                for (sort in parameters.sortModel) {
                    sortParam.columnName = parameters.sortModel[sort].colId;
                    if (sortParam.columnName === 'App Url') {
                        sortParam.columnName = 'app_url';
                    }

                    sortParam.order = parameters.sortModel[sort].sort;
                }

                params.sort = sortParam;
            }

            return http.get(baseUri + 'campaigns/public', {params: params});
        }

        /**
         * @ngdoc Get Info
         * @name ap.campaigns
         * @description saves a campaign to an affiliate and creates the affiliate campaign
         * @param parentCampaignId
         * @returns {*}
         */
        function createCampaign(parentCampaignId) {
            return http.post(baseUri + 'campaign', {
                campaignId: parentCampaignId
            });
        }

        /**
         * @ngdoc Update Campaign
         * @name ap.campaigns
         *
         * @description updates a campaign to an affiliate (only for affiliate postback now )
         * @param campaign
         * @returns {*}
         */
        function updateCampaign(campaign) {
            return http.put(baseUri + 'campaigns/' + campaign.affiliate_camp_id, {
                campaign: campaign
            });
        }

        function getAllCampTags() {
            return http.get(baseUri + 'campaigns/tags');
        }

        function getTagsByCampaign(campId) {
            var params = {
                campId: campId
            };

            return http.get(baseUri + 'tags', {params: params})
        }

        /**
         * @name ap.campaigns
         * 
         * @description Returns string of country names from passed in country codes
         * @string codes
         * @returns {string}
         */
        function countryCodesToNames(codes) {
            var countryNames = _.map(codes.split(','), function (countryCode) {
                return countryCodeToNameMap[countryCode];
            });

            return countryNames.join(', ');
        }

        /**
         * @name ap.campaigns
         *
         * @description Returns string of country code from passed in country name
         * @string codes
         * @returns {string}
         */
        function countryNameToCode(name) {
            var key = name.toLowerCase();
            return (key in countryNameToCodeMap) ? countryNameToCodeMap[key] : name;
        }

    }
})();
