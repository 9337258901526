(function () {
    'use strict';

    angular
        .module('ap')
        .component('affiliateCampaignsTable', affiliateCampaignsTable());

    /**
     * @ngdoc component
     * @name ap.campaigns
     *
     * @description Component function for rendering the company information page
     * @returns {{controller: AffiliateCampaignsController, controllerAs: string, templateUrl: string}}
     */
    function affiliateCampaignsTable() {
        return {
            controller: AffiliateCampaignsController,
            controllerAs: 'vm',
            templateUrl: '/views/campaigns.affiliates.table.html',
            bindings: {
                affiliate: '='
            }
        };
    }

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    AffiliateCampaignsController.$inject = [
        '$q',
        '$filter',
        '$uibModal',
        '_',
        'campaignsFactory',
        'ErrorFactory',
        'statsFactory',
        'Notification',
        'accountFactory',
        'agGridHelperFactory',
        'sideMenuFactory'
    ];

    /**
     * @ngdoc AffiliateCampaignsController
     * @name ap.campaigns
     *
     * @description Component function for rendering the company information page
     *
     * @param $q
     * @param $filter
     * @param $uibModal
     * @param _
     * @param campaignsFactory
     * @param ErrorFactory
     * @param statsFactory
     * @param Notification
     * @param accountFactory
     * @param agGridHelperFactory
     * @param sideMenuFactory
     * @constructor
     */
    function AffiliateCampaignsController($q,
                                          $filter,
                                          $uibModal,
                                          _,
                                          campaignsFactory,
                                          ErrorFactory,
                                          statsFactory,
                                          Notification,
                                          accountFactory,
                                          agGridHelperFactory,
                                          sideMenuFactory
    ) {
        var vm = this;
        var rowHeight = 30;
        const decimalPlaces = 6;

        vm.tooltip = 'This page includes all the campaigns that are currently assigned to you. ' +
            'Click the details link on the table to view specific notes and descriptions on the campaigns themselves';
        vm.pageSize = 15;
        vm.loading = true;
        vm.shortPublicCampaignList = false;

        vm.onFilterChange = onFilterChange;
        vm.openCampaignModal = openCampaignModal;
        vm.copySuccess = copySuccess;
        vm.filterByTags = filterByTags;

        vm.dataSource = {
            getRows: getRows
        };

        // Set default currency to USD
        vm.currency = {
            name: 'USD',
            conversion_rate: '1.0000000'
        };

        init();

        /**
         * @ngdoc directive
         * @name ap.campaigns
         *
         * @description begins the page
         */
        function init() {
            vm.getHeight = ((vm.pageSize + 1) * rowHeight) + 53 + 'px';
            vm.gridOptions = getGridOptions();
            var promises = {
                account: accountFactory.getInfo(),
                tags: campaignsFactory.getAllCampTags(),
                env: sideMenuFactory.getEnvVariables()
            };

            $q.all(promises)
                .then(function (response) {
                    vm.affiliate = response.account.data.data;
                    vm.allTags = response.tags.data.data;

                    if (response.env.data && response.env.data.currency) {
                        // Overwrite default USD if currency is set for affiliate
                        vm.currency = response.env.data.currency;
                    }

                    vm.shortPublicCampaignList = response.env.data.short_public_campaign_list;
                    if (vm.gridOptions.api) {
                        vm.gridOptions.api.setColumnDefs(getColumnDefs());
                    }
                })
                .catch(ErrorFactory.outputError);
        }

        /**
         * @ngdoc directive
         * @name ap.campaigns
         *
         * @param parameters
         */
        function getRows(parameters) {
            vm.gridOptions.api.showLoadingOverlay();

            campaignsFactory.getAffiliateCampaigns(parameters)
                .then(function (response) {
                    vm.showAppUrl = response.data.meta.showAppUrl;
                    vm.gridOptions.columnApi.setColumnVisible('App Url', vm.showAppUrl);
                    vm.campaigns = response.data.data;
                    parameters.successCallback(response.data.data, response.data.data.length);
                })
                .catch(function (error) {
                    parameters.failCallback();
                })
                .finally(function (response) {
                    vm.loading = false;
                    if (vm.gridOptions.api) {
                        vm.gridOptions.api.hideOverlay();
                        vm.gridOptions.api.sizeColumnsToFit();
                    }
                });
        }

        /**
         * @ngdoc directive
         * @name ap.campaigns
         *
         * @param campTags
         */
        function filterByTags(campTags) {
            if (campTags && campTags.length) {
                vm.gridOptions.api.showLoadingOverlay();

                // Set up filtering by campaign tags
                var tagIds = _.map(campTags, function (tag) {
                    return tag.id;
                });
                // Pass tag ID's in a string for server side filtering
                var filterTags = JSON.stringify(tagIds);

                campaignsFactory.getAffiliateCampaigns(filterTags)
                    .then(function (response) {
                        vm.showAppUrl = response.data.meta.showAppUrl;
                        vm.gridOptions.columnApi.setColumnVisible('App Url', vm.showAppUrl);
                        vm.campaigns = response.data.data;
                    })
                    .finally(function () {
                        vm.loading = false;
                        vm.gridOptions.rowModelType = 'normal';
                        onFilterChange();
                        if (vm.gridOptions.api) {
                            vm.gridOptions.api.hideOverlay();
                            vm.gridOptions.api.sizeColumnsToFit();
                        }
                    });
            } else {
                vm.gridOptions.rowModelType = 'pagination';
                vm.gridOptions.api.setDatasource(vm.dataSource);
            }
        }

        /**
         * @ngdoc directive
         * @name ap.campaigns
         *
         * @description sets the table data
         */
        function setGridData(dataArray) {
            vm.gridOptions.api.setRowData(dataArray);
        }

        /**
         * @ngdoc directive
         * @name ap.campaigns
         *
         * @description search filter for the table
         */
        function onFilterChange() {
            setGridData(vm.campaigns);
            vm.gridOptions.api.setQuickFilter(vm.campaignFilter);
            if (vm.gridOptions.api.rowModel.rowsToDisplay.length === 0) {
                setGridData([]);
                vm.gridOptions.api.showNoRowsOverlay();
            }
        }

        /**
         * @ngdoc directive
         * @name ap.campaigns
         *
         * @description sets the grid options for ag-grid
         */
        function getGridOptions() {
            var columnDefs = getColumnDefs();

            return {
                headerHeight: rowHeight,
                colWidth: 170,
                columnDefs: columnDefs,
                rowHeight: rowHeight,
                rowSelection: 'single',
                rowData: null,
                enableSorting: true,
                angularCompileRows: true,
                enableColResize: true,
                suppressMenuHide: true,
                suppressCellSelection: true,
                suppressContextMenu: true,
                suppressMenuMainPanel: true,
                suppressMenuColumnPanel: true,
                paginationPageSize: 100000,
                rowModelType: 'pagination',
                onGridReady: function (event) {
                    vm.gridOptions.api.setDatasource(vm.dataSource);
                }
            };
        }

        /**
         * @ngdoc component
         * @name ap.campaigns
         *
         * @description gets array of the columns on the campaigns for
         * ag-grid table
         */
        function getColumnDefs() {
            return [
                {
                    headerName: 'Actions',
                    cellRenderer: actionsRenderer,
                    width: 60,
                    minWidth: 60,
                    suppressMenu: true,
                    suppressFilter: true,
                    suppressSorting: true
                },
                {
                    headerName: 'ID',
                    field: 'camp_id',
                    width: 60,
                    minWidth:60,
                    floatingFilterComponent: agGridHelperFactory.DebouncedTextFilter,
                    suppressMenu: true
                }, {
                    headerName: 'Campaign Name',
                    field: 'camp_name',
                    cellRenderer: campaignNameRenderer,
                    width:300,
                    minWidth:200,
                    floatingFilterComponent: agGridHelperFactory.DebouncedTextFilter,
                    suppressMenu: true
                }, {
                    headerName: 'Click Link',
                    field: 'click_link',
                    cellRenderer: clickLinkRenderer,
                    width:70,
                    minWidth:30,
                    suppressMenu: true,
                    suppressFilter: true,
                    suppressSorting: true
                }, {
                    headerName: 'Payout',
                    field: 'camp_cpc',
                    cellRenderer: payoutRenderer,
                    valueGetter: payoutValueGetter,
                    width:70,
                    minWidth:40,
                    suppressMenu: true,
                    suppressFilter: true
                }, {
                    headerName: 'Cost Type',
                    field: 'cost_type',
                    valueGetter: costTypeRenderer,
                    width: 70,
                    minWidth:20,
                    floatingFilterComponent: agGridHelperFactory.DebouncedTextFilter,
                    suppressMenu: true
                }, {
                    headerName: 'Preview Link',
                    field: 'app_url',
                    cellRenderer: appUrlRenderer,
                    width: 180,
                    minWidth: 180,
                    colId: 'App Url',
                    suppressMenu: true,
                    suppressFilter: true
                }, {
                    headerName: 'Campaign Group',
                    field: 'group_id',
                    valueGetter: groupRenderer,
                    width:130,
                    minWidth:60,
                    suppressMenu: true,
                    suppressFilter: true,
                    hide: vm.shortPublicCampaignList ? true : false
                }, {
                    headerName: 'Vertical',
                    field: 'vertical_id',
                    valueGetter: verticalRenderer,
                    width:100,
                    minWidth:60,
                    suppressMenu: true,
                    suppressFilter: true
                }, {
                    headerName: 'Countries',
                    field: 'countries',
                    valueGetter: countriesRenderer,
                    width:100,
                    minWidth:60,
                    suppressMenu: true,
                    suppressFilter: true
                }, {
                    headerName: 'Status',
                    field: 'camp_active',
                    valueGetter: statusRenderer,
                    width:100,
                    minWidth:40,
                    floatingFilterComponent: agGridHelperFactory.DebouncedTextFilter,
                    suppressMenu: true
                }
            ];
        }

        /**
         * @ngdoc component
         * @name ap.campaigns
         * @param params
         * @description renders the campaign vertical
         * @returns {*}
         */
        function verticalRenderer(params) {
            var campaign;

            if ('affiliate_camp_id' in params.data) {
                campaign = params.data.parent_campaign;
            } else {
                campaign = params.data;
            }

            if (campaign.vertical !== null) {
                return campaign.vertical.vertical_name;
            } else {
                return 'No Vertical';
            }
        }

        /**
         * @ngdoc component
         * @name ap.campaigns
         * @param params
         * @description renders the campaign group
         * @returns {*}
         */
        function groupRenderer(params) {
            var campaign;

            if ('affiliate_camp_id' in params.data) {
                campaign = params.data.parent_campaign;
            } else {
                campaign = params.data;
            }

            if (campaign.group !== null && campaign.group !== undefined) {
                return campaign.group.group_name;
            } else {
                return 'No Campaign Group';
            }
        }

        /**
         * @ngdoc component
         * @name ap.campaigns
         * @param params
         * @description renders the campaign name
         * @returns {*}
         */
        function campaignNameRenderer(params) {
            // Replace all spaces with &nbsp;
            if ('affiliate_camp_id' in params.data) {
                return params.data.parent_campaign.camp_name.replace(/ /g, "&nbsp;");
            } else {
                return params.data.camp_name.replace(/ /g, "&nbsp;");
            }
        }

        /**
         * @ngdoc component
         * @name ap.campaigns
         * @param params
         * @description renders the countries information
         */
        function countriesRenderer(params) {
            var countries = params.data.countries === null && 'affiliate_camp_id' in params.data
                ? params.data.parent_campaign.countries
                : params.data.countries;

            if (countries === null || countries === '') {
                return 'No Countries';
            } else {
                return campaignsFactory.countryCodesToNames(countries);
            }
        }

        /**
         * @ngdoc component
         * @name ap.campaigns
         * @param params
         * @description renders the app_url information
         */
        function appUrlRenderer(params) {
            var url = 'affiliate_camp_id' in params.data ? params.data.parent_campaign.app_url : params.data.app_url;
            if (url === null || url === '') return 'No Preview Link';
            return "<a href=\'" + url + "\'" + " target='_blank'>" + url + "</a>";
        }

        /**
         * @ngdoc component
         * @name ap.campaigns
         * @description Renders the owner cell by its passed in value
         * @param params
         */
        function statusRenderer(params) {
            if ('affiliate_camp_id' in params.data) {
                if (parseInt(params.data.status) === 1) {
                    return 'Active';
                } else {
                    return 'Inactive';
                }
            } else {
                return "Public";
            }
        }

        /**
         * @ngdoc directive
         * @name ap.campaigns
         *
         * @description renders the cost type column
         */
        function costTypeRenderer(params) {
            var costType = params.data.cost_type;

            if (costType === null && 'affiliate_camp_id' in params.data) {
                costType = parseInt(params.data.parent_campaign.cost_type);
            } else {
                costType = parseInt(params.data.cost_type);
            }

            switch (costType) {
                case 0:
                    return 'CPC';
                case 1:
                    return vm.shortPublicCampaignList ? 'CPL' : 'CPA';
                case 2:
                    return 'Not Tracking';
                case 3:
                    return 'RevShare';
                default:
                    return 'Unknown';
            }
        }

        /**
         * @ngdoc directive
         * @name ap.campaigns
         *
         * @description renders the payout column
         */
        function payoutRenderer(params) {
            // If a public campaign and not an affiliate run campaign yet
            if (!('affiliate_camp_id' in params.data)) {
                if (params.data.cost_type === 3) {
                    return '-'
                } else {
                    var value = parseFloat(params.data.camp_cpc) * parseFloat(vm.currency.conversion_rate);
                    return statsFactory.formatCurrencyValue(vm.currency.name, value, decimalPlaces);
                }
            }

            var resolveToParentType = params.data.cost_type === null;
            var resolveToParentCost = params.data.traffic_cost === null;

            if (resolveToParentType) {
                if (parseInt(params.data.parent_campaign.cost_type) == 3) {
                    return '-';
                }
            } else {
                if (parseInt(params.data.cost_type) === 3) {
                    return '-';
                }
            }

            if (resolveToParentCost) {
                var value = parseFloat(params.data.parent_campaign.camp_cpc) * parseFloat(vm.currency.conversion_rate);
                return statsFactory.formatCurrencyValue(vm.currency.name, value, decimalPlaces);
            } else {
                var value = parseFloat(params.data.traffic_cost) * parseFloat(vm.currency.conversion_rate);
                return statsFactory.formatCurrencyValue(vm.currency.name, value, decimalPlaces);
            }
        }

        /**
         * @ngdoc payoutValueGetter
         * @name ap.campaigns
         *
         * @description gets value for sorting
         */
        function payoutValueGetter(params) {
            // Get cell rendered string and remove the '$'
            var value = payoutRenderer(params);
            value = parseFloat(value.replace('$', ''));

            return value;
        }

        /**
         * @ngdoc actionsRenderer
         * @name ap.campaigns
         *
         * @description renders the actions column
         */
        function actionsRenderer(params) {
            var widget = '<button class="btn btn-xs btn-danger ap-button" ng-click="vm.openCampaignModal(' + params.data.camp_id + ')">View</button>';

            if (!('affiliate_camp_id' in params.data)) {
                widget += "<button class='m-l-xs btn btn-xs btn-success ap-button' ng-click='vm.createCampaign(" + params.data.camp_id + ")'>Create Campaign</button>"
            }

            return widget;
        }

        /**
         * @ngdoc clickLinkRenderer
         * @name ap.campaigns
         *
         * @description renders the click link column
         */
        function clickLinkRenderer(params) {
            if (parseInt(params.data.status) === 0 || !('affiliate_camp_id' in params.data)) {
                return "N/A";
            }

            return "<button class=\'btn btn-info btn-xs ap-button\' ngclipboard data-clipboard-text="
                + params.data.click_link +
                " ngclipboard-success=\'vm.copySuccess(e)\' ngclipboard-error=\'vm.copyError(e)\'>Copy</button>";
        }

        /**
         * @ngdoc copySuccess
         * @name ap.campaigns
         *
         * @description pops a success message on screen
         */
        function copySuccess(e) {
            Notification.success({message: 'Campaign Link successfully copied!', title: 'Success'});
        }

        /**
         * @ngdoc getCampaignById
         * @name ap.campaigns
         *
         * @description gets a campaign by the ID
         * @param campaignId
         * @returns {*}
         */
        function getCampaignById(campaignId) {
            for (var i = 0; i < vm.campaigns.length; i++) {
                if (vm.campaigns[i]['camp_id'] == campaignId) {
                    return vm.campaigns[i];
                }
            }
        }

        /**
         * @ngdoc openCampaignModal
         * @name ap.campaigns
         *
         * @description opens the campaign details modal
         */
        function openCampaignModal(campaignId) {
            var campaign = getCampaignById(campaignId);

            $uibModal.open({
                controller: campaignModalController,
                controllerAs: 'vm',
                resolve: {
                    campaign: function () {
                        return campaign;
                    },
                    isAffiliateCampaign: function () {
                        return 'affiliate_camp_id' in campaign;
                    },
                    seeAppUrl: function () {
                        return vm.showAppUrl;
                    },
                    defaultPostback: function () {
                        return vm.affiliate['affiliate']['default_postback'] == null ? "" : vm.affiliate['affiliate']['default_postback'];
                    },
                    shortPublicCampaignList: function () {
                        return vm.shortPublicCampaignList;
                    },
                    currencyInfo: function () {
                        return {
                            'name': vm.currency.name,
                            'conversion_rate': vm.currency.conversion_rate,
                            'decimalPlaces': decimalPlaces
                        };
                    }
                },
                size: 'lg',
                templateUrl: '/views/modals/campaign.html'
            }).result.catch(function (resp) {
                if (['backdrop click'].indexOf(resp) === -1) {
                    $uibModalInstance.close();
                }
            });
        }

        /**
         * @ngdoc campaignModalController
         * @name ap.campaigns
         *
         * @description controller for the campaign details modal
         * @param campaign
         * @param isAffiliateCampaign
         * @param seeAppUrl
         * @param $uibModalInstance
         * @param ValidationFactory
         * @param defaultPostback
         * @param shortPublicCampaignList
         * @param currencyInfo
         * @param statsFactory
         */
        function campaignModalController(campaign, isAffiliateCampaign, seeAppUrl, $uibModalInstance, ValidationFactory, defaultPostback, shortPublicCampaignList, currencyInfo, statsFactory) {
            var vm = this;
            var costTypeMapping = {
                0: 'CPC',
                1: shortPublicCampaignList ? 'CPL' : 'CPA',
                2: 'No Cost Tracking',
                3: 'RevShare'
            };

            vm.loading = true;
            vm.error = false;
            vm.seeAppUrl = seeAppUrl;
            vm.isAffiliateCampaign = isAffiliateCampaign;
            vm.campaign = campaign;
            vm.close = close;
            vm.copySuccess = copySuccess;
            vm.updateCampaign = updateCampaign;
            vm.defaultPostback = defaultPostback;

            init();

            /**
             * @ngdoc init
             * @name ap.campaigns
             * @description Initializes the page
             */
            function init() {
                getCampaignValues();
                sanitizeValues();
                vm.loading = false;
            }

            /**
             * @ngdoc getCampaignValues
             * @name ap.campaigns
             *
             * @description gets the correct values for the campaign
             */
            function getCampaignValues() {
                campaignsFactory.getTagsByCampaign(vm.campaign.camp_id)
                    .then(function (response) {
                        vm.campaign.tags = response.data.data;
                    });

                if (isAffiliateCampaign) {
                    getAffiliateCampaignValues();
                } else {
                    getPublicCampaignValues();
                }

                // Convert Traffic Cost for display
                var value = parseFloat(vm.campaign.traffic_cost) * parseFloat(currencyInfo.conversion_rate);

                vm.campTrafficCost = vm.campaign.cost_type === 3 ? '-' : statsFactory.formatCurrencyValue(currencyInfo.name, value, currencyInfo.decimalPlaces);
            }

            /**
             * @ngdoc getAffiliateCampaignValues
             * @name ap.campaigns
             *
             * @description gets the correct values for the affiliate campaign
             */
            function getAffiliateCampaignValues() {
                if (parseInt(vm.campaign.status) === 0) {
                    vm.campaign.click_link = "";
                }

                if (vm.campaign.traffic_cost === null) {
                    vm.campaign.traffic_cost = vm.campaign.parent_campaign.camp_cpc;
                }

                if (vm.campaign.cost_type === null) {
                    vm.campaign.cost_type = vm.campaign.parent_campaign.cost_type;
                }

                if (parseInt(vm.campaign.cost_type) === 3) {
                    vm.campaign.traffic_cost = '-';
                }

                if (vm.campaign.caps === null) {
                    vm.campaign.caps = vm.campaign.parent_campaign.caps;
                }

                if (vm.campaign.countries === null) {
                    vm.campaign.countries = vm.campaign.parent_campaign.countries;
                }

                // Convert country codes to country names
                if (vm.campaign.countries) {
                    vm.campaign.countryNames = campaignsFactory.countryCodesToNames(vm.campaign.countries);
                }

                vm.campaign.parent_campaign.camp_description = decodeURIComponent(vm.campaign.parent_campaign.camp_description);
                vm.campaign.parent_campaign.creatives = decodeURIComponent(vm.campaign.parent_campaign.creatives);
                return;
            }

            /**
             * @ngdoc getPublicCampaignValues
             * @name ap.campaigns
             *
             * @description gets the correct values for the public campaign
             */
            function getPublicCampaignValues() {
                vm.campaign.parent_campaign = vm.campaign;
                vm.campaign.traffic_cost = vm.campaign.cost_type === 3 ? '-' : vm.campaign.camp_cpc;
                vm.campaign.parent_campaign.camp_description = decodeURIComponent(vm.campaign.camp_description);
                vm.campaign.parent_campaign.creatives = decodeURIComponent(vm.campaign.creatives);
                vm.campaign.status = vm.campaign.camp_active === 0;
            }

            /**
             * @ngdoc copySuccess
             * @name ap.campaigns
             *
             * @description pops a toast message
             */
            function copySuccess(e) {
                Notification.success({message: 'Campaign Link successfully copied!', title: 'Success'});
            }

            /**
             * @ngdoc sanitizeValues
             * @name ap.campaigns
             *
             * @description sanitizes the cost type to the correct string representation
             */
            function sanitizeValues() {
                vm.campaign.sanitizedCostType = costTypeMapping[vm.campaign.cost_type];
            }

            /**
             * @ngdoc close
             * @name ap.campaigns
             *
             * @description closes the modal
             */
            function close() {
                $uibModalInstance.close();
            }

            /**
             * @ngdoc updateCampaign
             * @name ap.campaigns
             *
             * @description creates an affiliate campaign for the user
             */
            function updateCampaign() {
                // Regex validator on the default postback
                if (vm.campaign.custom_postback) {
                    if (!ValidationFactory.pbUrl.test(vm.campaign.custom_postback) || !ValidationFactory.url.test(vm.campaign.custom_postback)) {
                        Notification.warning({
                            message: 'The postback URL you entered is not valid. Please enter a valid one.',
                            title: 'Warning'
                        });

                        return false;
                    }
                }

                vm.loading = true;

                campaignsFactory.updateCampaign(vm.campaign)
                    .then(function () {
                        Notification.success({message: 'Campaign has been successfully updated!', title: 'Success'});
                    })
                    .catch(ErrorFactory.outputError)
                    .finally(function () {
                        vm.loading = false;
                    });
            }
        }
    }
})();
