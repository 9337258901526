(function() {
    'use strict';

    angular
        .module('ap')
        .directive('spinner', spinner);

    /**
     * @ngdoc spinner directive
     * @name ap
     * @description directive for the spinner
     * @returns {{restrict: string, scope: {show: string}, transclude: boolean, template: string}}
     */
    function spinner() {
        return {
            restrict: 'AE',
            scope: {
                show: '=show',
            },
            transclude: true,
            template: "<div class=\'loader\' ng-show=\'show\'></div>",
        };
    }
})();
