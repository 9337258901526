(function () {
    'use strict';

    angular
        .module('ap')
        .factory('emailFactory', emailFactory);

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    emailFactory.$inject = ['http'];

    /**
     * @ngdoc emailFactoru
     * @name ap.email
     * @description factory for email
     * @param http
     * @returns {{saveNewEmail: (*|saveNewEmail)}}
     */
    function emailFactory(http) {
        var baseUri = '/api/v1/';

        var factory = {
            save: saveNewEmail
        };

        return factory;

        /**
         * @ngdoc createStatsTask
         * @name ap.email
         * @description saves a new email for the current user
         * @param email
         */
        function saveNewEmail(email) {
            return http.post(baseUri + 'email', {email: email});
        }
    }
})();
