(function() {
    'use strict';

    angular
        .module('ap')
        .component('changeEmail', changeEmail());

    /**
     * @ngdoc changeEmail
     * @name ap.email
     * @description component for the change email page
     * @returns {{controller: EmailController, controllerAs: string, templateUrl: string}}
     */
    function changeEmail() {
        return {
            controller: EmailController,
            controllerAs: 'vm',
            templateUrl: '/views/email.change.html'
        };
    }

    EmailController.$inject = ['emailFactory', 'ValidationFactory' , 'Notification'];

    /**
     * @ngdoc EmailController
     * @name ap.email
     * @description Controller for the new email page
     * @param emailFactory
     * @param ValidationFactory
     * @param Notification
     */
    function EmailController(emailFactory, ValidationFactory, Notification) {
        var vm = this;

        vm.saveNewEmail = saveNewEmail;
        vm.tooltip = 'Enter a new email here that will be used for the future.';
        vm.persisting = false;

        function saveNewEmail() {
            if (!validateNewEmail()) {
                return;
            }

            vm.persisting = true;

            emailFactory.save(vm.email.email_1)
                .then(function(response) {
                    Notification.success({
                        message: 'Your new email has been saved.',
                        title: 'Success'
                    });
                })
                .catch(function(response) {
                    var message;

                    if (response.data && response.data.message) {
                        message = response.data.message
                    } else {
                        message = 'Something went wrong and your new email was not saved. Please contact an administrator about this issue.';
                    }

                    Notification.error({
                        message: message,
                        title: 'Uh oh'
                    });
                })
                .finally(function(response) {
                    vm.persisting = false;
                })
        }

        /**
         * @ngdoc validateNewEmail
         * @name ap.email
         * @description validates the email fields are set correctly
         * @returns
         */
        function validateNewEmail() {
            // Ensures that both fields are entered
            if (!vm.email || (!('email_1' in vm.email) || !('email_2' in vm.email))) {
                Notification.warning({
                    message: 'Please ensure that both email fields are filled in.',
                    title: 'Warning'
                });

                return false;
            }

            // Ensures that the emails should be matching
            if (vm.email.email_1 !== vm.email.email_2) {
                Notification.warning({
                    message: 'Please the verify the email you entered are matching.',
                    title: 'Warning'
                });

                return false;
            }

            // Regex validator on the email
            if (!ValidationFactory.email.test(vm.email.email_1)) {
                Notification.warning({
                    message: 'The email you entered is not a valid email. Please enter a valid one.',
                    title: 'Warning'
                });

                return false;
            }

            return true;
        }
    }
})();
