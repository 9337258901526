(function() {
    angular
        .module('ap')
        .factory('ErrorFactory', ErrorFactory);

    /**
     * $inject Controller for dependency injection
     * @param {array}
     */
    ErrorFactory.$inject = ['Notification'];

    /**
     * @ngdoc factory
     * @name ap
     * @description validations on the front end
     */
    function ErrorFactory(Notification) {
        var factory = {
            outputError: outputError
        };

        return factory;

        function outputError(response) {
            var messageObject = {
                title: 'Error'
            }

            if (response.data) {
                messageObject.message = response.data.data.msg
            } else {
                messageObject.message = 'Something went wrong, please try again later.';
            }

            Notification.error(messageObject);
            return;
        }
    }
})();

